var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("mdc-header", {
        class: { "pm-top-app-bar--hidden": _vm.$store.state.topAppBarHidden },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "mdc-top-app-bar--fixed-adjust": true,
            "pm-top-app-bar--hidden--adjust": _vm.$store.state.topAppBarHidden,
          },
          staticStyle: { height: "100%" },
        },
        [
          _c(
            "transition",
            { attrs: { name: "pm-router", mode: "out-in", appear: "" } },
            [
              _c("router-view", {
                key:
                  true === _vm.$route.meta.keyed
                    ? _vm.$route.fullPath
                    : "router-view",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.notification
        ? _c("notification", {
            key: _vm.notification.id,
            attrs: { notification: _vm.notification },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.modals.showPremiumModal
        ? _c("premium-modal", { key: _vm.$store.state.modals.key })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }