var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pm-form mdc-layout-grid" }, [
    _vm.$route.params.email
      ? _c("div", { staticClass: "pm-form__title mdc-typography--headline6" }, [
          _vm._v(
            "\n    Ověřovací e-mail odeslán na " +
              _vm._s(this.$route.params.email) +
              "\n  "
          ),
        ])
      : _c("div", { staticClass: "pm-form__title mdc-typography--headline6" }, [
          _vm._v("\n    Ověřuji e-mail\n  "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }