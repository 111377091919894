var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "pm-form mdc-layout-grid",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "pm-form__title mdc-typography--headline6" }, [
        _vm._v("\n    Registrace\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pm-form__form mdc-layout-grid__inner" },
        [
          _c("mdc-form-text", {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet",
            attrs: {
              type: "text",
              name: "first_name",
              required: "",
              form: "register",
              label: "Jméno",
            },
          }),
          _vm._v(" "),
          _c("mdc-form-text", {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet",
            attrs: {
              type: "text",
              name: "last_name",
              required: "",
              form: "register",
              label: "Příjmení",
            },
          }),
          _vm._v(" "),
          _c("mdc-form-text", {
            staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
            attrs: {
              type: "email",
              name: "email",
              required: "",
              form: "register",
              label: "E-mail",
            },
            on: {
              input: function (value) {
                return (_vm.email = value)
              },
            },
          }),
          _vm._v(" "),
          _c("mdc-form-text", {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet",
            attrs: {
              type: "password",
              name: "password",
              label: "Heslo",
              required: "",
              form: "register",
            },
          }),
          _vm._v(" "),
          _c("mdc-form-text", {
            staticClass:
              "mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet",
            attrs: {
              type: "password",
              name: "password_confirmation",
              label: "Heslo znovu",
              required: "",
              form: "register",
            },
          }),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                staticClass: "mdc-button mdc-button--outlined",
                attrs: { href: _vm.vop },
              },
              [_vm._v("Podmínky")]
            ),
          ]),
          _vm._v(" "),
          _c("mdc-form-checkbox", {
            staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
            attrs: {
              name: "gdpr",
              label: "Souhlasím s podmínkami",
              required: "",
              form: "register",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pm-form__actions pm-form__actions--right" },
        [
          _c(
            "mdc-button",
            {
              attrs: {
                type: "submit",
                raised: "",
                disabled: _vm.loading,
                pm: false,
              },
            },
            [_vm._v("\n      Registrovat\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pm-form__actions" },
        [
          _c("mdc-link", { attrs: { to: { name: "login" }, pm: false } }, [
            _vm._v("\n      Přihlásit se\n    "),
          ]),
          _vm._v(" "),
          _c("mdc-link", { attrs: { to: { name: "forgot" }, pm: false } }, [
            _vm._v("\n      Zapomenuté heslo\n    "),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }