var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.$store.state.auth.auth.premium
      ? _c("div", { staticClass: "pm-welcome-grid mdc-layout-grid" }, [
          _c("div", { staticClass: "mdc-layout-grid__inner" }, [
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
              },
              [_c("welcome-back-card")],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "pm-courses-grid mdc-layout-grid" }, [
      _c("h1", { staticClass: "mdc-typography--headline2" }, [
        _vm._v("\n      Přehled kurzů\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mdc-layout-grid__inner" },
        [
          (!_vm.availableCourses || _vm.availableCourses.length === 0) &&
          _vm.loading
            ? _vm._l(12, function (index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet",
                  },
                  [_c("course-loading-card")],
                  1
                )
              })
            : [
                _vm._l(_vm.availableCourses, function (course) {
                  return _c(
                    "div",
                    {
                      key: course.id,
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet",
                    },
                    [
                      _c("course-card", {
                        key: course.id,
                        attrs: { course: course },
                      }),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.wip
                  ? _c("course-wip-card", {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet",
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }