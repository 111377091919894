var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pm-auth-grid mdc-layout-grid" }, [
      _c("div", { staticClass: "pm-auth-grid__inner mdc-layout-grid__inner" }, [
        _c(
          "div",
          {
            staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
          },
          [
            _c(
              "div",
              {
                staticClass: "mdc-typography--headline1",
                staticStyle: { "text-align": "center" },
              },
              [_vm._v("\n        Stránka nenalezena!\n      ")]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }