import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import categories from './categories';
import courses from './courses';
import lessons from './lessons';
import notifications from './notifications';
import requests from './requests';
import modals from './modals';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    topAppBarHidden: false,
  },
  mutations: {
    hideTopAppBar(state) {
      if (!state.topAppBarHidden) {
        Vue.set(state, 'topAppBarHidden', true);
      }
    },
    showTopAppBar(state) {
      if (state.topAppBarHidden) {
        Vue.set(state, 'topAppBarHidden', false);
      }
    },
  },
  modules: {
    auth: auth,
    categories: categories,
    courses: courses,
    lessons: lessons,
    notifications: notifications,
    requests: requests,
    modals: modals,
  },
});
