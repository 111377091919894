var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "mdc",
      staticClass: "mdc-linear-progress",
      attrs: {
        role: "progressbar",
        "aria-label": "Progress bar",
        "aria-valuemin": "0",
        "aria-valuemax": "1",
        "aria-valuenow": "0",
      },
    },
    [
      _c("div", { staticClass: "mdc-linear-progress__buffering-dots" }),
      _vm._v(" "),
      _c("div", { staticClass: "mdc-linear-progress__buffer" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "mdc-linear-progress__bar mdc-linear-progress__primary-bar",
        },
        [
          _c("span", {
            ref: "iteration",
            staticClass: "mdc-linear-progress__bar-inner",
          }),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "mdc-linear-progress__bar mdc-linear-progress__secondary-bar",
      },
      [_c("span", { staticClass: "mdc-linear-progress__bar-inner" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }