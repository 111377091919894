export default {
  'login': {
    method: 'POST',
    url: '/users/login',
  },
  'register': {
    method: 'POST',
    url: '/users/register',
  },
  'forgot': {
    method: 'POST',
    url: '/users/forgot',
  },
  'change': {
    method: 'POST',
    url: '/users/reset',
  },
  'refresh': {
    method: 'POST',
    url: '/users/refresh',
  },
  'verify': {
    method: 'POST',
    url: '/users/verify',
    guest: true,
  },
  'resend': {
    method: 'POST',
    url: '/users/resend-verification',
    guest: true,
  },
  'categories.index': {
    url: '/categories',
  },
  'courses.show': {
    url: '/courses/:id',
  },
  'lessons.show': {
    url: '/lessons/:id',
  },
  'lessons.star': {
    method: 'POST',
    url: '/lessons/:id/add-star',
  },
  'logout': {
    method: 'POST',
    url: '/users/logout',
  },
  'profile.show': {
    url: '/users/profile',
  },
  'profile.update': {
    method: 'PUT',
    url: '/users/profile',
  },
  'premiums.store': {
    method: 'POST',
    url: '/users/premiums',
  },
  'contact-form.store': {
    method: 'POST',
    url: '/contact-form',
  },
};
