<template>
  <div
    class="mdc-card pm-card--lesson"
    @click.prevent="onClick"
  >
    <div
      :class="{'mdc-card__media': true, 'mdc-card__media--square': true, 'mdc-card__media--square--active': l.stars > 0, 'mdc-card__media--square--locked': !l.is_available}"
      :style="{'--rotation': (index * 20) + 'deg'}"
    >
      <div class="mdc-theme--on-primary mdc-card__media-content pm-card--lesson__index">
        {{ index }}
      </div>
    </div>
    <div class="pm-card--lesson__inner">
      <h3
        class="pm-card--lesson__name"
        :class="{'pm-card--lesson__name--locked': !l.is_available}"
      >
        {{ l.name }}
      </h3>
      <div class="pm-card--lesson__actions">
        <div class="pm-card--lesson__levels mdc-typography--button mdc-theme--text-hint-on-light">
          <div :class="{'pm-card--lesson__level': true, 'pm-card--lesson__level--active': l.stars > 0, 'mdc-theme--text-primary-on-light': l.stars > 0}">
            <span class="pm-card--lesson__level__name">Level 1</span>
            <span class="pm-card--lesson__level__indicator" />
          </div>
          <div :class="{'pm-card--lesson__level': true, 'pm-card--lesson__level--active': l.stars > 1, 'mdc-theme--text-primary-on-light': l.stars > 1}">
            <span class="pm-card--lesson__level__name">Level 2</span>
            <span class="pm-card--lesson__level__indicator" />
          </div>
          <div :class="{'pm-card--lesson__level': true, 'pm-card--lesson__level--active': l.stars > 2, 'mdc-theme--text-primary-on-light': l.stars > 2}">
            <span class="pm-card--lesson__level__name">Level 3</span>
            <span class="pm-card--lesson__level__indicator" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LessonCard',
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return ({
      l: {...this.lesson},
    });
  },
  watch: {
    lesson(value) {
      this.l = {...value};
    },
  },
  methods: {
    onClick() {
      if (this.lesson && this.lesson.is_available) {
        this.$router.push({name: 'lessons.show', params: {courseId: this.$route.params.id, lessonId: this.lesson.id, menu: this.lesson.stars === 3}, query: {level: Math.max(1, Math.min(3, this.lesson.stars + 1))}});
      } else {
        this.$store.commit('showPremiumModal');
      }
    },
  },
};
</script>
