var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mdc-contact-form mdc-layout-grid" }, [
    _c("div", { staticClass: "mdc-layout-grid__inner" }, [
      _c(
        "div",
        { staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12" },
        [
          _c("div", { staticClass: "mdc-contact-form__greet" }, [
            _c("strong", [_vm._v("Ptejte se")]),
            _vm._v(
              _vm._s(
                _vm.$store.state.auth.auth.vocativ
                  ? ", " + _vm.$store.state.auth.auth.vocativ + "!"
                  : ""
              ) + "\n      "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12" },
        [
          _c(
            "form",
            {
              attrs: { method: "POST" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "mdc-layout-grid__inner" }, [
                _c("div", { staticClass: "mdc-layout-grid__cell--span-12" }, [
                  _c(
                    "label",
                    {
                      ref: "mdc",
                      staticClass: "mdc-text-field mdc-text-field--textarea",
                    },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.content,
                            expression: "content",
                          },
                        ],
                        staticClass: "mdc-text-field__input",
                        attrs: {
                          "aria-labelledby": "my-label-id",
                          rows: "8",
                          cols: "40",
                          required: "",
                          disabled: _vm.submited || _vm.loading,
                        },
                        domProps: { value: _vm.content },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.content = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm._m(2),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "mdc-layout-grid__cell--span-12",
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "mdc-button mdc-button--raised",
                        attrs: {
                          type: "submit",
                          disabled: _vm.submited || _vm.loading,
                        },
                      },
                      [
                        _c("span", { staticClass: "mdc-button__label" }, [
                          _vm._v("\n                Zeptat se\n              "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons mdc-button__icon",
                            attrs: { "aria-hidden": "true" },
                          },
                          [_vm._v("arrow_right_alt")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12" },
      [
        _c("div", { staticClass: "mdc-contact-form__text" }, [
          _vm._v("\n        Pořád se vám plete "),
          _c("em", [_vm._v("it’s")]),
          _vm._v(" a "),
          _c("em", [_vm._v("its")]),
          _vm._v(
            "? Nejste si jisti předpřítomným časem? Narazili jste na výraz, kterému úplně nerozumíte? Ptejte se! "
          ),
          _c("strong", [_vm._v("Rádi poradíme")]),
          _vm._v(". 💡\n      "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12" },
      [_c("hr", { staticClass: "mdc-contact-form__hr" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mdc-notched-outline" }, [
      _c("span", { staticClass: "mdc-notched-outline__leading" }),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-notched-outline__notch" }, [
        _c(
          "label",
          { staticClass: "mdc-floating-label", attrs: { id: "my-label-id" } },
          [_vm._v("Jak můžeme poradit")]
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-notched-outline__trailing" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }