import Vue from 'vue';

import {shouldRefresh} from '../api';

export default {
  state: {
    categories: [],
    loading: false,
    accessed: null,
  },
  getters: {},
  mutations: {
    setCategories(state, categories) {
      Vue.set(state, 'categories', categories);
    },
    loading(state, flag) {
      Vue.set(state, 'loading', flag);
    },
    accessed(state, accessed) {
      Vue.set(state, 'accessed', accessed);
    },
    clearCategories(state) {
      Vue.set(state, 'categories', []);
      Vue.set(state, 'accessed', null);
    },
  },
  actions: {
    getCategories(store, force = false) {
      if (force || shouldRefresh(store.state.accessed, 10*60*1000)) {
        store.commit('loading', true);

        return store.dispatch('request', {
          route: 'categories.index',
        }).then((response) => {
          store.commit('accessed', Date.now());
          store.commit('setCategories', response.json);

          return response;
        }).finally(() => {
          store.commit('loading', false);
        });
      }

      return Promise.resolve();
    },
  },
};
