<template>
  <div>
    <div
      v-if="!course && loading"
      class="mdc-layout-grid pm-course-grid"
    >
      <h1 class="mdc-typography--headline2">
        <fake :length="12" />
      </h1>
      <div class="pm-course-grid__progress pm-card--course__progress">
        <mdc-progress
          indeterminate
          :progress="0"
          class="pm-card--course__progress__line"
        />
        <div class="pm-card--course__progress__count mdc-typography--button">
          <span :class="['pm-card--course__progress__from', 'mdc-theme--text-hint-on-light']">
            <fake :length="3" />
          </span>
        </div>
      </div>
      <section-lessons-fake
        v-for="i in 2"
        :key="i"
      />
    </div>
    <div
      v-else
      class="mdc-layout-grid pm-course-grid"
    >
      <h1 class="mdc-typography--headline2">
        {{ course.name }}
      </h1>
      <div class="pm-course-grid__progress pm-card--course__progress">
        <mdc-progress
          :progress="calculatedProgress"
          class="pm-card--course__progress__line"
        />
        <div class="pm-card--course__progress__count mdc-typography--button">
          <span :class="['pm-card--course__progress__from', active ? 'mdc-theme--text-primary-on-light' : 'mdc-theme--text-hint-on-light']">
            {{ course.progress }}
          </span>
          <span class="mdc-theme--text-hint-on-light">
            / {{ course.lessons_count }}
          </span>
        </div>
      </div>
      <section-lessons
        v-for="(section, i) in course.sections"
        :key="section.id"
        :section="section"
        :index="indexMethod(i)"
      />
    </div>
    <router-link
      :class="{'mdc-fab': true, 'pm-fab--back': true}"
      aria-label="Zpět"
      :to="{name: 'index'}"
      tag="button"
    >
      <div class="mdc-fab__ripple" />
      <span class="mdc-fab__icon material-icons">keyboard_arrow_left</span>
    </router-link>
  </div>
</template>

<script>
import MdcProgress from '../components/MdcProgress.vue';
import SectionLessons from '../components/SectionLessons.vue';
import SectionLessonsFake from '../components/SectionLessonsFake.vue';
import Fake from '../components/Fake.vue';

export default {
  name: 'CourseShow',
  components: {
    MdcProgress,
    SectionLessons,
    Fake,
    SectionLessonsFake,
  },
  data() {
    return ({
      loading: true,
    });
  },
  computed: {
    course() {
      return this.$store.getters.course(this.$route.params.id);
    },
    active() {
      return (this.course.progress || 0) > 0;
    },
    calculatedProgress() {
      return (1 / 100) * (100 / this.course.lessons_count * this.course.progress);
    },
  },
  created() {
    this.$store.dispatch('getCourse', {id: this.$route.params.id}).catch((error) => {
      const status = error.status.status;

      if (status == 404) {
        this.$router.push({name: 'index'}).then(() => {
          this.$store.dispatch('error', 'Kurz nenalezen!');
        });
      } else {
        this.$router.push({name: 'index'}).then(() => {
          this.$store.commit('showPremiumModal');
        });
      }
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    indexMethod(i) {
      if (i === 0) {
        return 0;
      }

      let sum = 0;

      for (let x = 0; x < i; x++) {
        sum += this.course.sections[x].lessons_count;
      }

      return sum;
    },
  },
};
</script>
