<template>
  <div class="mdc-menu-surface--anchor">
    <div @click="toggle">
      <slot name="button" />
    </div>
    <div
      ref="mdcMenu"
      class="mdc-menu mdc-menu-surface"
    >
      <ul
        class="mdc-list"
        role="menu"
        aria-hidden="true"
        aria-orientation="vertical"
        tabindex="-1"
      >
        <slot />
      </ul>
    </div>
  </div>
</template>

<script>
import {MDCMenu} from '@material/menu';

export default {
  name: 'MdcMenu',
  props: {
    icon: {
      type: String,
      default: 'menu',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
      mdcMenu: null,
      menuOpen: this.open,
    });
  },
  watch: {
    open(value) {
      this.menuOpen = value;

      if (this.mdcMenu) {
        this.mdcMenu.open = this.menuOpen;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.mdcMenu = new MDCMenu(this.$refs.mdcMenu);

      this.mdcMenu.open = this.menuOpen;

      this.mdcMenu.listen('MDCMenuSurface:opened', () => {
        this.menuOpen = true;
        this.$emit('input', this.menuOpen);
      });

      this.mdcMenu.listen('MDCMenuSurface:closed', () => {
        this.menuOpen = false;
        this.$emit('input', this.menuOpen);
      });
    });
  },
  destroyed() {
    if (this.mdcMenu) {
      this.mdcMenu = this.mdcMenu.destroy();
    }
  },
  methods: {
    toggle() {
      this.menuOpen = !this.menuOpen;

      if (this.mdcMenu) {
        this.mdcMenu.open = this.menuOpen;
      }
    },
  },
};
</script>
