<template>
  <form
    ref="form"
    class="pm-form mdc-layout-grid"
    @submit.prevent="onSubmit"
  >
    <div class="pm-form__title mdc-typography--headline6">
      Obnovte si heslo zasláním odkazu na e-mail.
    </div>
    <div class="pm-form__form mdc-layout-grid__inner">
      <mdc-form-text
        type="email"
        name="email"
        required
        form="forgot"
        label="E-mailová adresa"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
      />
    </div>
    <div class="pm-form__actions pm-form__actions--right">
      <mdc-button
        type="submit"
        :disabled="loading"
        raised
        :pm="false"
      >
        Odeslat
      </mdc-button>
    </div>
    <div class="pm-form__actions">
      <mdc-link
        :to="{name: 'register'}"
        :pm="false"
      >
        Registrace
      </mdc-link>
      <mdc-link
        :to="{name: 'login'}"
        :pm="false"
      >
        Přihlásit se
      </mdc-link>
    </div>
  </form>
</template>

<script>
import MdcFormText from '../components/MdcFormText.vue';
import MdcButton from '../components/MdcButton.vue';
import MdcLink from '../components/MdcLink.vue';

export default {
  name: 'Forgot',
  components: {
    MdcFormText,
    MdcButton,
    MdcLink,
  },
  data() {
    return ({
      loading: false,
    });
  },
  mounted() {
    this.$store.commit('clearErrors');
  },
  methods: {
    onSubmit() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.$store.dispatch('authLoader', true);
      this.$store
          .dispatch('request', {route: 'forgot', data: this.$refs.form})
          .then((data) => {
            this.$router.push({name: 'change', params: {sent: true}}).then(() => {
              this.$store.dispatch('info', 'E-mail odeslán!');
            }).finally(() => {
              this.$store.dispatch('authLoader', false);
              this.loading = false;
            });
          }).catch(() => {
            this.$store.dispatch('authLoader', false);
            this.loading = false;
          });
    },
  },
};
</script>
