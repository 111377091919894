<template>
  <div class="pm-explode-animation">
    <div
      v-for="index in 40"
      :key="index"
      :style="{
        '--scale': Math.max(.5, Math.random() * 5),
        '--left': String(Math.round(Math.random() * 100)) + '%',
        '--top': String(Math.round(Math.random() * 100)) + '%',
        'color': `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`,
        'animation-delay': String(Math.random() * 20) + 's'
      }"
      class="material-icons pm-explode-animation__alternate"
    >
      {{ alternate }}
    </div>
    <div
      :class="{
        'material-icons': true,
        'pm-explode-animation__main': true,
        'mdc-theme--primary': success,
        'mdc-theme--secondary': !success,
      }"
    >
      {{ main }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExplodeAnimation',
  props: {
    main: {
      type: String,
      required: true,
    },
    alternate: {
      type: String,
      required: true,
    },
    success: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
