import Vue from 'vue';

import {shouldRefresh} from '../api';

export default {
  state: {
    lessons: {},
    loading: false,
  },
  getters: {
    lesson(state) {
      return (id) => {
        return state.lessons[id];
      };
    },
  },
  mutations: {
    setLesson(state, lesson) {
      lesson.accessed = Date.now();

      Vue.set(state.lessons, lesson.id, lesson);
    },
    loading(state, flag) {
      Vue.set(state, 'loading', flag);
    },
    clearLessons(state) {
      Vue.set(state, 'lessons', {});
    },
  },
  actions: {
    getLesson(store, payload) {
      if (payload.force || (!store.getters.lesson(payload.id) || shouldRefresh(store.state.accessed, 10*60*1000))) {
        store.commit('loading', true);

        return store.dispatch('request', {
          route: 'lessons.show',
          params: {id: payload.id},
        }).then((response) => {
          store.commit('setLesson', response.json);

          return response;
        }).finally(() => {
          store.commit('loading', false);
        });
      }

      return Promise.resolve();
    },
    addStar(store, payload) {
      return store.dispatch('request', {
        route: 'lessons.star',
        params: {id: payload.lessonId},
        body: {max: payload.max},
      }).then(() => {
        store.dispatch('getCourse', {id: payload.courseId, force: true});
        store.dispatch('getLesson', {id: payload.lessonId, force: true});
        store.dispatch('getCategories', true);
      }).catch(() => {});
    },
  },
};
