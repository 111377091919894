<template>
  <div class="mdc-contact-form mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
        <div class="mdc-contact-form__greet">
          <strong>Ptejte se</strong>{{ $store.state.auth.auth.vocativ ? ', ' + $store.state.auth.auth.vocativ + '!' : '' }}
        </div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
        <div class="mdc-contact-form__text">
          Pořád se vám plete <em>it’s</em> a <em>its</em>? Nejste si jisti předpřítomným časem? Narazili jste na výraz, kterému úplně nerozumíte? Ptejte se! <strong>Rádi poradíme</strong>. 💡
        </div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
        <hr class="mdc-contact-form__hr">
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
        <form
          method="POST"
          @submit.prevent="handleSubmit"
        >
          <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-12">
              <label
                ref="mdc"
                class="mdc-text-field mdc-text-field--textarea"
              >
                <textarea
                  v-model="content"
                  class="mdc-text-field__input"
                  aria-labelledby="my-label-id"
                  rows="8"
                  cols="40"
                  required
                  :disabled="submited || loading"
                />
                <span class="mdc-notched-outline">
                  <span class="mdc-notched-outline__leading" />
                  <span class="mdc-notched-outline__notch">
                    <label
                      id="my-label-id"
                      class="mdc-floating-label"
                    >Jak můžeme poradit</label>
                  </span>
                  <span class="mdc-notched-outline__trailing" />
                </span>
              </label>
            </div>
            <div
              style="text-align: center;"
              class="mdc-layout-grid__cell--span-12"
            >
              <button
                type="submit"
                class="mdc-button mdc-button--raised"
                :disabled="submited || loading"
              >
                <span class="mdc-button__label">
                  Zeptat se
                </span>
                <i
                  class="material-icons mdc-button__icon"
                  aria-hidden="true"
                >arrow_right_alt</i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {MDCTextField} from '@material/textfield';

export default {
  name: 'ContactForm',
  data() {
    return {
      loading: false,
      content: '',
      submited: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.mdc = new MDCTextField(this.$refs.mdc);
    });
  },
  destroyed() {
    if (this.mdc) {
      this.mdc = this.mdc.destroy();
    }
  },
  methods: {
    handleSubmit() {
      if (this.loading || this.submited) {
        return;
      }

      this.loading = true;

      const data = {
        content: this.content,
        source: 'ask',
      };

      this.$store.dispatch('request', {route: 'contact-form.store', data: data}).then(() => {
        this.submited = true;

        this.$store.dispatch('info', 'Děkujeme! E-mail s odpovědí odešleme co nejdříve.');
      }, () => {}).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
