<template>
  <div
    class="pm-form mdc-layout-grid"
  >
    <div class="pm-form__title mdc-typography--headline6">
      Odhlašuji
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  created() {
    this.$store.dispatch('authLoader', true);
    this.$store.dispatch('logout').finally(() => {
      this.$router.push({name: 'login'}).then(() => {
        this.$store.dispatch('info', 'Úspěšně odhlášeno!');
      }).finally(() => {
        this.$store.dispatch('authLoader', false);
      });
    });
    this.$store.commit('clearAuth');
  },
};
</script>
