<template>
  <div>
    <label
      ref="mdcInput"
      class="pm-text-field mdc-text-field mdc-text-field--outlined"
    >
      <input
        v-model="val"
        :type="type"
        :name="name"
        :value="val"
        :required="required"
        class="mdc-text-field__input"
        :aria-labelledby="type + name"
        :placeholder="placeholder"
      >
      <div class="mdc-notched-outline">
        <div class="mdc-notched-outline__leading" />
        <div class="mdc-notched-outline__notch">
          <span
            :id="type + name"
            class="mdc-floating-label"
          >{{ label || name }}</span>
        </div>
        <div class="mdc-notched-outline__trailing" />
      </div>
    </label>
    <div class="pm-errors">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="pm-errors__error mdc-theme--error mdc-typography--caption"
      >
        <span class="pm-errors__error__icon material-icons">error</span> {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import {MDCTextField} from '@material/textfield';

export default {
  name: 'MdcFormText',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    form: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return ({
      val: this.$props.value,
      mdcInput: null,
    });
  },
  computed: {
    errors() {
      const allErrors = this.$store.state.requests.errors;

      if (allErrors && allErrors[this.form]) {
        if (allErrors[this.form].errors && allErrors[this.form].errors[this.name]) {
          return allErrors[this.form].errors[this.name];
        }
      }

      return [];
    },
  },
  watch: {
    val() {
      this.$emit('input', this.val);
    },
  },
  created() {
    this.$nextTick(() => {
      this.mdcInput = new MDCTextField(this.$refs.mdcInput);
    });
  },
  destroyed() {
    if (this.mdcInput) {
      this.mdcInput = this.mdcInput.destroy();
    }
  },
};
</script>
