<template>
  <div class="pm-auth-grid mdc-layout-grid">
    <div class="pm-auth-grid__inner mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
        <div
          class="mdc-typography--headline1"
          style="text-align: center;"
        >
          Stránka nenalezena!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Fallback',
};
</script>
