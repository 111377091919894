<template>
  <span class="pm-fake">
    <template>
      <span
        v-for="index in length"
        :key="index"
        class="pm-fake__text"
      >&nbsp;</span>
    </template>
  </span>
</template>

<script>
export default {
  name: 'Fake',
  props: {
    length: {
      type: Number,
      required: true,
    },
  },
};
</script>
