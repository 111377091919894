var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.query.token && _vm.$route.query.email
    ? _c(
        "form",
        {
          ref: "form",
          staticClass: "pm-form mdc-layout-grid",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mdc-typography--headline6 pm-form__title" },
            [_vm._v("\n    Změna hesla\n  ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pm-form__form mdc-layout-grid__inner" },
            [
              _c("mdc-form-text", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                attrs: {
                  type: "hidden",
                  name: "token",
                  required: "",
                  form: "change",
                  value: _vm.$route.query.token,
                },
              }),
              _vm._v(" "),
              _c("mdc-form-text", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                attrs: {
                  type: "hidden",
                  name: "email",
                  required: "",
                  form: "change",
                  value: _vm.$route.query.email,
                },
              }),
              _vm._v(" "),
              _c("mdc-form-text", {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
                attrs: {
                  type: "password",
                  name: "password",
                  required: "",
                  form: "change",
                  label: "Nové heslo",
                },
              }),
              _vm._v(" "),
              _c("mdc-form-text", {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
                attrs: {
                  type: "password",
                  name: "password_confirmation",
                  required: "",
                  form: "change",
                  label: "Nové heslo znovu",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pm-form__actions pm-form__actions--right" },
            [
              _c(
                "mdc-button",
                {
                  attrs: {
                    type: "submit",
                    raised: "",
                    disabled: _vm.loading,
                    pm: false,
                  },
                },
                [_vm._v("\n      Změnit heslo\n    ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pm-form__actions" },
            [
              _c(
                "mdc-link",
                { attrs: { to: { name: "register" }, pm: false } },
                [_vm._v("\n      Registrace\n    ")]
              ),
              _vm._v(" "),
              _c("mdc-link", { attrs: { to: { name: "login" }, pm: false } }, [
                _vm._v("\n      Přihlásit se\n    "),
              ]),
            ],
            1
          ),
        ]
      )
    : _c("div", { staticClass: "pm-form mdc-layout-grid" }, [
        _c("div", { staticClass: "mdc-typography--headline6 pm-form__title" }, [
          _vm._v("\n    Zkontrolujte váš email\n  "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pm-form__actions" },
          [
            _c("mdc-link", { attrs: { to: { name: "register" }, pm: false } }, [
              _vm._v("\n      Registrace\n    "),
            ]),
            _vm._v(" "),
            _c("mdc-link", { attrs: { to: { name: "login" }, pm: false } }, [
              _vm._v("\n      Přihlásit se\n    "),
            ]),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }