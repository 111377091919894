<template>
  <div class="pm-lesson-show">
    <div
      class="pm-lesson-show__header"
    >
      <div class="pm-lesson-show__header__actions">
        <router-link
          class="mdc-icon-button"
          :to="{name: 'courses.show', params: {id: $route.params.courseId}}"
        >
          <img
            class="mdc-icon-button__icon"
            src="../../assets/close.svg"
            alt="Ukončit"
            title="Ukončit"
          >
        </router-link>
        <span
          v-if="lives === 0"
          class="mdc-icon-button"
          @click="reload"
        >
          <img
            class="mdc-icon-button__icon"
            src="../../assets/reload.svg"
            alt="Začít znovu"
            title="Začít znovu"
          >
        </span>
        <span
          v-else
          class="mdc-icon-button"
          @click="reload"
        >
          <img
            class="mdc-icon-button__icon"
            src="../../assets/reload--disabled.svg"
            alt="Začít znovu"
            title="Začít znovu"
          >
        </span>
      </div>
      <div class="pm-lesson-show__header__progress">
        <mdc-progress
          :progress="calculatedProgress"
          :indeterminate="loading"
        />
      </div>
      <div class="pm-lesson-show__header__hearts">
        <span
          class="mdc-icon-button pm-button--heart"
        >
          <transition
            name="pm-heart"
            appear
          >
            <img
              v-if="lives > 0"
              class="mdc-icon-button__icon"
              src="../../assets/heart.svg"
              alt="Život"
              title="Život"
            >
          </transition>
        </span>
        <span
          class="mdc-icon-button pm-button--heart"
        >
          <transition
            name="pm-heart"
            appear
          >
            <img
              v-if="lives > 1"
              class="mdc-icon-button__icon"
              src="../../assets/heart.svg"
              alt="Život"
              title="Život"
            >
          </transition>
        </span>
        <span
          class="mdc-icon-button pm-button--heart"
        >
          <transition
            name="pm-heart"
            appear
          >
            <img
              v-if="lives > 2"
              class="mdc-icon-button__icon"
              src="../../assets/heart.svg"
              alt="Život"
              title="Život"
            >
          </transition>
        </span>
      </div>
    </div>
    <transition
      name="pm-router"
      mode="out-in"
      appear
    >
      <div
        v-if="loading"
        key="loading"
        class="mdc-typography--headline2 pm-lesson-show__menu mdc-layout-grid"
      >
        Načítám lekci
      </div>
      <div
        v-else-if="menu"
        key="menu"
        class="pm-lesson-show__menu mdc-layout-grid"
      >
        <span class="mdc-typography--headline3">
          Výběr levelů
        </span>
        <div class="pm-lesson-show__levels">
          <mdc-button
            :pm="false"
            raised
            @click.native="select(1)"
          >
            Level 1
          </mdc-button>
          <mdc-button
            :disabled="lesson.stars < 1"
            raised
            :pm="false"
            @click.native="select(2)"
          >
            Level 2
          </mdc-button>
          <mdc-button
            raised
            :disabled="lesson.stars < 2"
            :pm="false"
            @click.native="select(3)"
          >
            Level 3
          </mdc-button>
        </div>
      </div>
      <div
        v-else-if="lives > 0 && question"
        key="question"
        class="pm-lesson-show__inner"
      >
        <transition
          name="pm-router"
          mode="out-in"
          appear
        >
          <question
            :key="String(seed) + String(question.id)"
            :question="question"
            :transcript="transcript"
            @ok="ok"
            @fail="fail"
          />
        </transition>
      </div>
      <div
        v-else-if="lives > 0"
        key="success"
        class="pm-lesson-show__menu"
      >
        <span class="mdc-typography--headline2 mdc-theme--primary">
          Úspěch
        </span>
        <explode-animation
          main="star_border"
          alternate="mood"
        />
        <div>
          <mdc-button
            v-if="questionGroup < 3"
            raised
            :pm="false"
            @click.native="nextLevel"
          >
            Další level
          </mdc-button>
          <mdc-link
            :pm="false"
            :to="{name: 'courses.show', params: {id: $route.params.courseId}}"
          >
            Seznam lekcí
          </mdc-link>
        </div>
      </div>
      <div
        v-else
        key="fail"
        class="pm-lesson-show__menu"
      >
        <span class="mdc-typography--headline2 mdc-theme--secondary">
          Neúspěch
        </span>
        <explode-animation
          :success="false"
          main="cancel"
          alternate="mood_bad"
        />
        <div>
          <mdc-button
            raised
            :pm="false"
            class="pm-button--red"
            @click.native="reload"
          >
            Znovu
          </mdc-button>
          <mdc-link
            :pm="false"
            :to="{name: 'courses.show', params: {id: $route.params.courseId}}"
            class="pm-button--red"
          >
            Seznam lekcí
          </mdc-link>
        </div>
      </div>
    </transition>
    <div
      :class="{
        'pm-lesson-show__footer': true,
        'pm-lesson-show__footer--ok': state === 'ok',
        'pm-lesson-show__footer--fail': state === 'fail',
      }"
    >
      <div class="mdc-layout-grid pm-lesson-show__footer__inner">
        <div>
          <transition
            name="pm-router"
            mode="out-in"
            appear
          >
            <span
              v-if="state === 'ok'"
              class="pm-lesson-show__footer__status"
            >
              <span class="material-icons">
                check
              </span>
              Správná odpověď
            </span>
            <span
              v-else-if="state === 'fail'"
              class="pm-lesson-show__footer__status"
            >
              <span class="material-icons">
                close
              </span>
              Špatná odpověď
            </span>
            <mdc-button
              v-else-if="lives > 0 && audioQuestion && state === 'idle' && !transcript"
              :pm="false"
              pre-icon="volume_off"
              :style="{
                '--mdc-theme-primary': '#949897',
              }"
              @click.native="showTranscript"
            >
              Teď nemohu poslouchat
            </mdc-button>
          </transition>
        </div>
        <div>
          <mdc-button
            :disabled="state==='idle' || menu || !question"
            :class="{
              'pm-button--small': true,
              'pm-button--error': state === 'fail',
              'pm-button--gradient': state === 'ok',
            }"
            @click.native="next"
          >
            Pokračovat
          </mdc-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Question from '../components/Question.vue';
import MdcButton from '../components/MdcButton.vue';
import MdcLink from '../components/MdcLink.vue';
import MdcProgress from '../components/MdcProgress.vue';
import ExplodeAnimation from '../components/ExplodeAnimation.vue';

export default {
  name: 'LessonShow',
  components: {
    Question,
    MdcButton,
    MdcLink,
    MdcProgress,
    ExplodeAnimation,
  },
  data() {
    const limitedLevel = Math.min(3, Number(this.$route.query.level) || 1);

    if (Number(this.$route.query.level || 1) > 3) {
      this.$router.replace({query: {level: this.questionGroup = limitedLevel, menu: this.$route.query.menu}});
    }

    return ({
      questionGroup: limitedLevel,
      loading: true,
      lives: 0,
      current: 0,
      state: 'idle',
      seed: Math.random(),
      menu: this.$route.params.menu || false,
      transcript: false,
    });
  },
  computed: {
    lesson() {
      return this.$store.getters.lesson(this.$route.params.lessonId);
    },
    level() {
      if (!this.lesson) {
        return null;
      }

      return this.lesson.levels.find((el) => {
        return el.level === this.questionGroup;
      });
    },
    questions() {
      if (!this.lesson) {
        return [];
      }

      return this.level.questions;
    },
    question() {
      return this.questions[this.current];
    },
    calculatedProgress() {
      return (1 / 100) * (100 / (this.questions.length || 1) * this.current);
    },
    audioQuestion() {
      return this.question && this.question.type === 'AUDIO';
    },
  },
  created() {
    this.$store.dispatch('getLesson', {id: this.$route.params.lessonId}).then(() => {
      if (this.questionGroup > this.lesson.stars + 1) {
        this.questionGroup = this.lesson.stars + 1;
        this.$router.replace({query: {level: this.questionGroup = this.lesson.stars + 1, menu: this.$route.query.menu}});
      }
    }).catch((error) => {
      const status = error.status.status;

      if (status == 404) {
        this.$router.push({name: 'index'}).then(() => {
          this.$store.dispatch('error', 'Kurz nenalezen!');
        });
      } else {
        this.$router.push({name: 'index'}).then(() => {
          this.$store.commit('showPremiumModal');
        });
      }
    }).finally(() => {
      this.lives = 3;
      this.loading = false;
    });
  },
  methods: {
    select(level) {
      this.questionGroup = Math.min(this.lesson.stars + 1, level);
      this.menu = false;
    },
    reload() {
      this.state = 'idle';
      this.current = 0;
      this.lives = 3;
      this.seed = Math.random();
    },
    skip() {
      this.next();
    },
    showTranscript() {
      this.transcript = true;
    },
    ok() {
      this.state = 'ok';
    },
    fail() {
      this.state = 'fail';
    },
    next() {
      if (this.state === 'fail') {
        this.lives = Math.max(0, this.lives - 1);
      }

      this.current = Math.min(this.questions.length, this.current + 1);

      this.state = 'idle';

      this.checkWin();
    },
    nextLevel() {
      this.questionGroup++;
      this.reload();
    },
    checkWin() {
      if (this.lives > 0 && !this.question) {
        if (this.questionGroup > this.lesson.stars) {
          this.$store.dispatch('addStar', {lessonId: this.lesson.id, courseId: this.$route.params.courseId, max: this.questionGroup});
        }
      }
    },
  },
};
</script>
