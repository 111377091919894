<template>
  <div>
    <div
      v-if="!$store.state.auth.auth.premium"
      class="pm-welcome-grid mdc-layout-grid"
    >
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <welcome-back-card />
        </div>
      </div>
    </div>
    <div class="pm-courses-grid mdc-layout-grid">
      <h1 class="mdc-typography--headline2">
        Přehled kurzů
      </h1>
      <div class="mdc-layout-grid__inner">
        <template v-if="(!availableCourses || availableCourses.length === 0) && loading">
          <div
            v-for="index in 12"
            :key="index"
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet"
          >
            <course-loading-card />
          </div>
        </template>
        <template v-else>
          <div
            v-for="course in availableCourses"
            :key="course.id"
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet"
          >
            <course-card
              :key="course.id"
              :course="course"
            />
          </div>
          <course-wip-card
            v-if="wip"
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCard from '../components/CourseCard.vue';
import CourseLoadingCard from '../components/CourseLoadingCard.vue';
import CourseWipCard from '../components/CourseWipCard.vue';
import WelcomeBackCard from '../components/WelcomeBackCard.vue';

export default {
  name: 'CourseIndex',
  components: {
    CourseCard,
    CourseLoadingCard,
    CourseWipCard,
    WelcomeBackCard,
  },
  data() {
    return ({
      loading: true,
    });
  },
  computed: {
    courses() {
      return this.$store.getters.courses;
    },
    availableCourses() {
      return this.courses.filter((course) => {
        return course.published_at;
      });
    },
    wip() {
      return this.courses.some((course) => {
        return !course.published_at;
      });
    },
  },
  created() {
    this.$store.dispatch('getCategories').then(() => {
      this.loading = false;
    });
  },
};
</script>
