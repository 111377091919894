var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "mdcField", staticClass: "mdc-form-field" }, [
      _c("div", { ref: "mdcCheckbox", staticClass: "mdc-checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.val,
              expression: "val",
            },
          ],
          staticClass: "mdc-checkbox__native-control",
          attrs: {
            id: "checkbox" + _vm.name,
            type: "checkbox",
            name: _vm.name,
            required: _vm.required,
          },
          domProps: {
            checked: _vm.val,
            checked: Array.isArray(_vm.val)
              ? _vm._i(_vm.val, null) > -1
              : _vm.val,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.val,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.val = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.val = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.val = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-checkbox__background" }, [
          _c(
            "svg",
            {
              staticClass: "mdc-checkbox__checkmark",
              attrs: { viewBox: "0 0 24 24" },
            },
            [
              _c("path", {
                staticClass: "mdc-checkbox__checkmark-path",
                attrs: { fill: "none", d: "M1.73,12.91 8.1,19.28 22.79,4.59" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mdc-checkbox__mixedmark" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-checkbox__ripple" }),
      ]),
      _vm._v(" "),
      _c("label", { attrs: { for: "checkbox" + _vm.name } }, [
        _vm._v(_vm._s(_vm.label || _vm.name)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pm-errors" },
      _vm._l(_vm.errors, function (error, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass:
              "pm-errors__error mdc-theme--error mdc-typography--caption",
          },
          [
            _c(
              "span",
              { staticClass: "pm-errors__error__icon material-icons" },
              [_vm._v("error")]
            ),
            _vm._v(" " + _vm._s(error) + "\n    "),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }