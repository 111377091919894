import { render, staticRenderFns } from "./LessonShow.vue?vue&type=template&id=71be2c34&"
import script from "./LessonShow.vue?vue&type=script&lang=js&"
export * from "./LessonShow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Applications/MAMP/htdocs/lingly-web-master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71be2c34')) {
      api.createRecord('71be2c34', component.options)
    } else {
      api.reload('71be2c34', component.options)
    }
    module.hot.accept("./LessonShow.vue?vue&type=template&id=71be2c34&", function () {
      api.rerender('71be2c34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/LessonShow.vue"
export default component.exports