var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pm-lesson-show" },
    [
      _c("div", { staticClass: "pm-lesson-show__header" }, [
        _c(
          "div",
          { staticClass: "pm-lesson-show__header__actions" },
          [
            _c(
              "router-link",
              {
                staticClass: "mdc-icon-button",
                attrs: {
                  to: {
                    name: "courses.show",
                    params: { id: _vm.$route.params.courseId },
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "mdc-icon-button__icon",
                  attrs: {
                    src: require("../../assets/close.svg"),
                    alt: "Ukončit",
                    title: "Ukončit",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm.lives === 0
              ? _c(
                  "span",
                  { staticClass: "mdc-icon-button", on: { click: _vm.reload } },
                  [
                    _c("img", {
                      staticClass: "mdc-icon-button__icon",
                      attrs: {
                        src: require("../../assets/reload.svg"),
                        alt: "Začít znovu",
                        title: "Začít znovu",
                      },
                    }),
                  ]
                )
              : _c(
                  "span",
                  { staticClass: "mdc-icon-button", on: { click: _vm.reload } },
                  [
                    _c("img", {
                      staticClass: "mdc-icon-button__icon",
                      attrs: {
                        src: require("../../assets/reload--disabled.svg"),
                        alt: "Začít znovu",
                        title: "Začít znovu",
                      },
                    }),
                  ]
                ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pm-lesson-show__header__progress" },
          [
            _c("mdc-progress", {
              attrs: {
                progress: _vm.calculatedProgress,
                indeterminate: _vm.loading,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "pm-lesson-show__header__hearts" }, [
          _c(
            "span",
            { staticClass: "mdc-icon-button pm-button--heart" },
            [
              _c("transition", { attrs: { name: "pm-heart", appear: "" } }, [
                _vm.lives > 0
                  ? _c("img", {
                      staticClass: "mdc-icon-button__icon",
                      attrs: {
                        src: require("../../assets/heart.svg"),
                        alt: "Život",
                        title: "Život",
                      },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "mdc-icon-button pm-button--heart" },
            [
              _c("transition", { attrs: { name: "pm-heart", appear: "" } }, [
                _vm.lives > 1
                  ? _c("img", {
                      staticClass: "mdc-icon-button__icon",
                      attrs: {
                        src: require("../../assets/heart.svg"),
                        alt: "Život",
                        title: "Život",
                      },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "mdc-icon-button pm-button--heart" },
            [
              _c("transition", { attrs: { name: "pm-heart", appear: "" } }, [
                _vm.lives > 2
                  ? _c("img", {
                      staticClass: "mdc-icon-button__icon",
                      attrs: {
                        src: require("../../assets/heart.svg"),
                        alt: "Život",
                        title: "Život",
                      },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "pm-router", mode: "out-in", appear: "" } },
        [
          _vm.loading
            ? _c(
                "div",
                {
                  key: "loading",
                  staticClass:
                    "mdc-typography--headline2 pm-lesson-show__menu mdc-layout-grid",
                },
                [_vm._v("\n      Načítám lekci\n    ")]
              )
            : _vm.menu
            ? _c(
                "div",
                {
                  key: "menu",
                  staticClass: "pm-lesson-show__menu mdc-layout-grid",
                },
                [
                  _c("span", { staticClass: "mdc-typography--headline3" }, [
                    _vm._v("\n        Výběr levelů\n      "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pm-lesson-show__levels" },
                    [
                      _c(
                        "mdc-button",
                        {
                          attrs: { pm: false, raised: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.select(1)
                            },
                          },
                        },
                        [_vm._v("\n          Level 1\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "mdc-button",
                        {
                          attrs: {
                            disabled: _vm.lesson.stars < 1,
                            raised: "",
                            pm: false,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.select(2)
                            },
                          },
                        },
                        [_vm._v("\n          Level 2\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "mdc-button",
                        {
                          attrs: {
                            raised: "",
                            disabled: _vm.lesson.stars < 2,
                            pm: false,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.select(3)
                            },
                          },
                        },
                        [_vm._v("\n          Level 3\n        ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm.lives > 0 && _vm.question
            ? _c(
                "div",
                { key: "question", staticClass: "pm-lesson-show__inner" },
                [
                  _c(
                    "transition",
                    {
                      attrs: { name: "pm-router", mode: "out-in", appear: "" },
                    },
                    [
                      _c("question", {
                        key: String(_vm.seed) + String(_vm.question.id),
                        attrs: {
                          question: _vm.question,
                          transcript: _vm.transcript,
                        },
                        on: { ok: _vm.ok, fail: _vm.fail },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.lives > 0
            ? _c(
                "div",
                { key: "success", staticClass: "pm-lesson-show__menu" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mdc-typography--headline2 mdc-theme--primary",
                    },
                    [_vm._v("\n        Úspěch\n      ")]
                  ),
                  _vm._v(" "),
                  _c("explode-animation", {
                    attrs: { main: "star_border", alternate: "mood" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm.questionGroup < 3
                        ? _c(
                            "mdc-button",
                            {
                              attrs: { raised: "", pm: false },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.nextLevel.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("\n          Další level\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "mdc-link",
                        {
                          attrs: {
                            pm: false,
                            to: {
                              name: "courses.show",
                              params: { id: _vm.$route.params.courseId },
                            },
                          },
                        },
                        [_vm._v("\n          Seznam lekcí\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { key: "fail", staticClass: "pm-lesson-show__menu" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mdc-typography--headline2 mdc-theme--secondary",
                    },
                    [_vm._v("\n        Neúspěch\n      ")]
                  ),
                  _vm._v(" "),
                  _c("explode-animation", {
                    attrs: {
                      success: false,
                      main: "cancel",
                      alternate: "mood_bad",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "mdc-button",
                        {
                          staticClass: "pm-button--red",
                          attrs: { raised: "", pm: false },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.reload.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n          Znovu\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "mdc-link",
                        {
                          staticClass: "pm-button--red",
                          attrs: {
                            pm: false,
                            to: {
                              name: "courses.show",
                              params: { id: _vm.$route.params.courseId },
                            },
                          },
                        },
                        [_vm._v("\n          Seznam lekcí\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "pm-lesson-show__footer": true,
            "pm-lesson-show__footer--ok": _vm.state === "ok",
            "pm-lesson-show__footer--fail": _vm.state === "fail",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mdc-layout-grid pm-lesson-show__footer__inner" },
            [
              _c(
                "div",
                [
                  _c(
                    "transition",
                    {
                      attrs: { name: "pm-router", mode: "out-in", appear: "" },
                    },
                    [
                      _vm.state === "ok"
                        ? _c(
                            "span",
                            { staticClass: "pm-lesson-show__footer__status" },
                            [
                              _c("span", { staticClass: "material-icons" }, [
                                _vm._v("\n              check\n            "),
                              ]),
                              _vm._v(
                                "\n            Správná odpověď\n          "
                              ),
                            ]
                          )
                        : _vm.state === "fail"
                        ? _c(
                            "span",
                            { staticClass: "pm-lesson-show__footer__status" },
                            [
                              _c("span", { staticClass: "material-icons" }, [
                                _vm._v("\n              close\n            "),
                              ]),
                              _vm._v(
                                "\n            Špatná odpověď\n          "
                              ),
                            ]
                          )
                        : _vm.lives > 0 &&
                          _vm.audioQuestion &&
                          _vm.state === "idle" &&
                          !_vm.transcript
                        ? _c(
                            "mdc-button",
                            {
                              style: {
                                "--mdc-theme-primary": "#949897",
                              },
                              attrs: { pm: false, "pre-icon": "volume_off" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.showTranscript.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            Teď nemohu poslouchat\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "mdc-button",
                    {
                      class: {
                        "pm-button--small": true,
                        "pm-button--error": _vm.state === "fail",
                        "pm-button--gradient": _vm.state === "ok",
                      },
                      attrs: {
                        disabled:
                          _vm.state === "idle" || _vm.menu || !_vm.question,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.next.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Pokračovat\n        ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }