var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pm-card pm-card--welcome mdc-card" }, [
    _c(
      "div",
      {
        staticClass: "pm-card__content pm-card--welcome__background",
        style: { "background-image": "url(" + _vm.welcomeBackCard + ")" },
      },
      [
        _c("div", { staticClass: "pm-card--welcome__intro" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/welcome.svg"),
              title: "Vítejte",
              alt: "Vítejte",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "mdc-theme--on-primary pm-card--welcome__intro__text",
            },
            [
              _c("span", { staticClass: "mdc-typography--headline2" }, [
                _c("span", { staticStyle: { "font-weight": "400" } }, [
                  _vm._v(
                    "Vítejte zpět" +
                      _vm._s(
                        _vm.$store.state.auth.auth.vocativ
                          ? ", " + _vm.$store.state.auth.auth.vocativ + "!"
                          : "!"
                      )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "mdc-typography--button mdc-theme--text-secondary-on-dark",
                },
                [
                  _vm._v(
                    "Chcete získat přístup do všech kurzů? Vyzkoušejte Lingly Premium!"
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "mdc-button",
          {
            staticClass: "pm-button--white",
            nativeOn: {
              click: function ($event) {
                return _vm.$store.commit("showPremiumModal")
              },
            },
          },
          [_vm._v("\n      Vyzkoušet PREMIUM\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }