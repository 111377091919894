<template>
  <div :class="classes">
    <audio
      ref="audio_answer"
      :src="question.audio_answer"
    />
    <div class="mdc-theme--text-hint-on-light pm-question__ask">
      {{ ask }}
    </div>
    <div>
      <template v-if="audio">
        <div
          v-if="transcript"
          class="pm-question__challenge"
        >
          {{ question.challenge }}
        </div>
        <mdc-button
          :pm="false"
          pre-icon="volume_up"
          @click.native="playQuestion"
        >
          Přehrát
          <audio
            ref="audio_question"
            :src="question.audio_question"
          />
        </mdc-button>
        <div
          v-if="question.image"
          style="margin-top: 2rem;"
        >
          <img
            :src="question.image"
            :alt="question.challenge"
            :title="question.challenge"
            class="pm-question__image"
          >
        </div>
      </template>
      <template v-else-if="image">
        <div class="pm-question__challenge">
          {{ question.challenge }}
        </div>
        <img
          :src="question.image"
          :alt="question.challenge"
          :title="question.challenge"
          class="pm-question__image"
        >
      </template>
      <template v-else-if="input">
        <div
          class="pm-question__challenge"
        >
          {{ question.challenge }}
        </div>
        <form
          ref="formQuestion"
          class="pm-form mdc-layout-grid"
          @submit.prevent="confirmAnswer"
        >
          <mdc-form-text
            type="text"
            name="inputAnswer"
            label="Odpověď"
            required
            form="formQuestion"
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
          />
          <div class="pm-form__actions pm-form__actions--right">
            <mdc-button
              type="submit"
              raised
              style="width: 100%;"
            >
              zkontrolovat
            </mdc-button>
          </div>
        </form>
        <button
          class="pm-button mdc-button mdc-ripple-upgraded"
          style="width: 100%;"
          @click="prompter()"
        >
          ? nápověda
        </button>
        <div
          v-if="question.image"
          style="margin-top: 2rem;"
        >
          <img
            :src="question.image"
            :alt="question.challenge"
            :title="question.challenge"
            class="pm-question__image"
          >
        </div>
      </template>
      <template v-else-if="image">
        <div class="pm-question__challenge">
          {{ question.challenge }}
        </div>
        <img
          :src="question.image"
          :alt="question.challenge"
          :title="question.challenge"
          class="pm-question__image"
        >
      </template>
      <template v-else-if="cloze">
        <div
          class="pm-question__challenge"
        >
          <div style="display: inline-block;">
            {{ clozeBefore }}
          </div>
          <transition
            name="pm-router"
            mode="out-in"
            appear
          >
            <div
              v-if="false !== selected"
              class="pm-question__fake-cloze pm-question__fake-cloze--show"
            >
              {{ clozeInner }}
            </div>
            <div
              v-else
              class="pm-question__fake-cloze"
            >
              &nbsp;
              &nbsp;
              &nbsp;
            </div>
          </transition>
          <div style="display: inline-block;">
            {{ clozeAfter }}
          </div>
        </div>
        <img
          v-if="question.image"
          :src="question.image"
          :alt="question.challenge"
          :title="question.challenge"
          class="pm-question__image"
        >
      </template>
      <template v-else>
        <div
          class="pm-question__challenge"
        >
          {{ question.challenge }}
        </div>
        <img
          v-if="question.image"
          :src="question.image"
          :alt="question.challenge"
          :title="question.challenge"
          class="pm-question__image"
        >
      </template>
    </div>
    <div
      v-if="!input"
      class="pm-question__answers"
    >
      <mdc-button
        v-for="answer in randomAnswers"
        :key="answer.index"
        :instantiate="false"
        :disabled="false !== selected && answer.index !== 0"
        :data-selected="answer.index === selected"
        :data-answered="false !== selected"
        outlined
        :class="{
          'pm-question__answer': true,
          'pm-button--transition': true,
          'pm-game-button': true,
          'pm-game-button--success': answer.index === 0,
          'pm-game-button--error': answer.index !== 0,
        }"
        @click.native="onClick(answer.index)"
      >
        {{ answer.answer }}
      </mdc-button>
    </div>

    <div
      v-if="question.english_translation || question.czech_translation"
      class="pm-question__translations"
    >
      <transition
        name="pm-router"
        appear
      >
        <div
          v-if="question.english_translation && false !== selected"
          class="pm-question__translation"
        >
          <img
            class="pm-question__translation-flag"
            src="../../assets/en.svg"
            alt="Anglický překlad"
            title="Anglický překlad"
          >
          <span class="mdc-typography--button pm-question__translation-text">{{ question.english_translation }}</span>
        </div>
      </transition>
      <transition
        name="pm-router"
        appear
      >
        <div
          v-if="question.czech_translation && false !== selected"
          class="pm-question__translation"
        >
          <img
            class="pm-question__translation-flag"
            src="../../assets/cz.svg"
            alt="Český překlad"
            title="Český překlad"
          >
          <span class="mdc-typography--button pm-question__translation-text">{{ question.czech_translation }}</span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import MdcButton from './MdcButton.vue';
import MdcFormText from '../components/MdcFormText.vue';
export default {
  name: 'Question',
  components: {
    MdcButton,
    MdcFormText,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    transcript: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return ({
      selected: false,
      inputAnswer: null,
    });
  },
  computed: {
    audio() {
      return this.question.type === 'AUDIO';
    },
    image() {
      return this.question.type === 'IMAGE';
    },
    cloze() {
      return this.question.type === 'CLOZE';
    },
    input() {
      return this.question.type === 'INPUT';
    },
    clozeMax() {
      return 5;
    },
    clozeBefore() {
      if (!this.cloze) {
        return '';
      }

      return this.question.challenge.split(/\[[^\]]+\]/)[0];
    },
    clozeInner() {
      if (!this.cloze) {
        return '';
      }

      return /\[([^\]]+)\]/.exec(this.question.challenge)[1];
    },
    clozeAfter() {
      if (!this.cloze) {
        return '';
      }

      return this.question.challenge.split(/\[[^\]]+\]/)[1];
    },
    ask() {
      let def = null;

      switch (this.question.type) {
        case 'AUDIO':
          def = 'Jak byste odpověděli?';
          break;

        case 'CLOZE':
          def = 'Vyberte správnou možnost';
          break;

        case 'INPUT':
          def = 'Jak se napíše';
          break;

        default:
          def = 'Jak se anglicky řekne';
          break;
      }

      return this.question.task || def;
    },
    answers() {
      return this.question.answers;
    },
    randomAnswers() {
      const a = [];

      this.answers.map((answer, index) => {
        a.push({
          index: index,
          answer: answer,
        });
      });

      return a.sort(() => Math.random() - 0.5);
    },
    classes() {
      return {
        'pm-question': true,
        'pm-question--column': ['AUDIO', 'TEXT'].indexOf(this.question.type) === -1 ? false : true,
      };
    },
  },
  methods: {
    prompter() {
      console.log('testik');
      const answers = this.answers[0].split(';');
      let currentAnswer = this.$refs.formQuestion.elements[0].value;

      if (!currentAnswer) {
        this.$refs.formQuestion.elements[0].focus();
      } else {
        currentAnswer = currentAnswer.toLowerCase();
      }

      let length = currentAnswer.length;
      if (!length) {
        length = 1;
      }
      const promterValue = answers[0].substring(0, length);
      const promterText = answers[0].substring(0, length + 1);
      const promterFirst = answers[0].substring(0, 1);

      if (promterFirst.toLowerCase() !== currentAnswer.substring(0, 1)) {
        this.$refs.formQuestion.elements[0].value = promterFirst;
        return;
      }

      if (promterValue.toLowerCase() === currentAnswer) {
        // console.log('text', promterText);
        this.$refs.formQuestion.elements[0].value = promterText;
      } else {
        let done = false;
        for (let i = 1; i < currentAnswer.length; i++) {
          /*    console.log('start');
          console.log(i);
          console.log(answers[0].substring(0, i));
          console.log(currentAnswer.substring(0, i));
          console.log('stop');*/
          if (answers[0].substring(0, i).toLowerCase() !== currentAnswer.substring(0, i)) {
            if (i > 1 && !done) {
              done = true;
              this.$refs.formQuestion.elements[0].value = answers[0].substring(0, i);
              // console.log('counted', answers[0].substring(0, i));
            }
          }
        }
        if (!done) {
          this.$refs.formQuestion.elements[0].value = promterFirst;
          // console.log('first', promterFirst);
        }
      }


      // console.log(currentAnswer);
    },
    confirmAnswer(e) {
      e.preventDefault();
      const answers = this.answers[0].split(';');
      answers.forEach((answer) => {
        answer = answer.toLowerCase();
      });
      let currentAnswer = this.$refs.formQuestion.elements[0].value;
      currentAnswer = currentAnswer.toLowerCase();

      if (answers.includes(currentAnswer)) {
        this.$emit('ok');
      } else {
        this.$emit('fail');
      }
    },
    playQuestion() {
      const audio = this.$refs.audio_question;

      try {
        audio.currentTime = 0;
        audio.play();
      } catch (error) {
        console.log(error);
      }
    },
    playAnswer() {
      const audio = this.$refs.audio_answer;

      try {
        audio.currentTime = 0;
        audio.play();
      } catch (error) {
        console.log(error);
      }
    },
    onClick(index) {
      this.playAnswer();

      if (false !== this.selected) {
        return;
      }

      this.selected = index;

      if (index === 0) {
        this.$emit('ok');
      } else {
        this.$emit('fail');
      }
    },
  },
};
</script>
