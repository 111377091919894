var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      {
        ref: "mdcInput",
        staticClass: "pm-text-field mdc-text-field mdc-text-field--outlined",
      },
      [
        _vm.type === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.val,
                  expression: "val",
                },
              ],
              staticClass: "mdc-text-field__input",
              attrs: {
                name: _vm.name,
                required: _vm.required,
                "aria-labelledby": _vm.type + _vm.name,
                placeholder: _vm.placeholder,
                type: "checkbox",
              },
              domProps: {
                value: _vm.val,
                checked: Array.isArray(_vm.val)
                  ? _vm._i(_vm.val, _vm.val) > -1
                  : _vm.val,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.val,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.val,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.val = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.val = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.val = $$c
                  }
                },
              },
            })
          : _vm.type === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.val,
                  expression: "val",
                },
              ],
              staticClass: "mdc-text-field__input",
              attrs: {
                name: _vm.name,
                required: _vm.required,
                "aria-labelledby": _vm.type + _vm.name,
                placeholder: _vm.placeholder,
                type: "radio",
              },
              domProps: { value: _vm.val, checked: _vm._q(_vm.val, _vm.val) },
              on: {
                change: function ($event) {
                  _vm.val = _vm.val
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.val,
                  expression: "val",
                },
              ],
              staticClass: "mdc-text-field__input",
              attrs: {
                name: _vm.name,
                required: _vm.required,
                "aria-labelledby": _vm.type + _vm.name,
                placeholder: _vm.placeholder,
                type: _vm.type,
              },
              domProps: { value: _vm.val, value: _vm.val },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.val = $event.target.value
                },
              },
            }),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-notched-outline" }, [
          _c("div", { staticClass: "mdc-notched-outline__leading" }),
          _vm._v(" "),
          _c("div", { staticClass: "mdc-notched-outline__notch" }, [
            _c(
              "span",
              {
                staticClass: "mdc-floating-label",
                attrs: { id: _vm.type + _vm.name },
              },
              [_vm._v(_vm._s(_vm.label || _vm.name))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mdc-notched-outline__trailing" }),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pm-errors" },
      _vm._l(_vm.errors, function (error, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass:
              "pm-errors__error mdc-theme--error mdc-typography--caption",
          },
          [
            _c(
              "span",
              { staticClass: "pm-errors__error__icon material-icons" },
              [_vm._v("error")]
            ),
            _vm._v(" " + _vm._s(error) + "\n    "),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }