import Vue from 'vue';

import router from '../router';

function optimalRefresh(auth) {
  const future = futureRefresh(auth);

  const now = Date.now();

  return Math.max(0, future - now);
}

function futureRefresh(auth) {
  const refreshTime = (Math.min(15*60*1000, Math.max(1*60*1000, (auth.expires_in * 1000) / 4)));
  const future = auth.accessed + refreshTime;

  return future;
}

function shouldRefresh(auth) {
  const optimal = optimalRefresh(auth);

  return optimal <= 0;
}

let token = null;

try {
  token = JSON.parse(localStorage.getItem('auth'));
} catch (e) {
  token = null;
}

export default {
  state: {
    auth: token,
    authTimeout: null,
    intended: '',
    loading: false,
    promise: null,
    authProgress: false,
    authProgressTimeout: null,
    profile: null,
  },
  getters: {},
  mutations: {
    setProfile(state, response) {
      Vue.set(state, 'profile', response.json);
    },
    setIntended(state, url) {
      Vue.set(state, 'intended', url);
    },
    setAuth(state, auth) {
      if (auth) {
        auth.accessed = Date.now();
      }

      localStorage.setItem('auth', JSON.stringify(auth));

      Vue.set(state, 'auth', auth);
    },
    clearAuth(state) {
      Vue.set(state, 'auth', null);

      if (state.authTimeout) {
        Vue.set(state, 'authTimeout', clearTimeout(state.authTimeout));
      }

      Vue.set(state, 'intended', '');
      Vue.set(state, 'profile', null);

      localStorage.clear();
    },
    setTimeout(state, timeout) {
      if (state.authTimeout) {
        clearTimeout(state.authTimeout);
      }

      Vue.set(state, 'authTimeout', timeout);
    },
    loading(state, flag) {
      Vue.set(state, 'loading', flag);
    },
    promise(state, promise) {
      Vue.set(state, 'promise', promise);
    },
    authProgress(state, flag) {
      Vue.set(state, 'authProgress', flag);
    },
    authProgressTimeout(state, timeout) {
      if (null !== state.authProgressTimeout) {
        clearTimeout(state.authProgressTimeout);
      }

      Vue.set(state, 'authProgressTimeout', timeout);
    },
  },
  actions: {
    refresh(store, payload) {
      if (store.state.auth) {
        if ((payload && payload.force) || shouldRefresh(store.state.auth)) {
          store.commit('loading', true);

          const promise = store.dispatch('request', {
            route: 'refresh',
          }).then((response) => {
            store.commit('setAuth', response.json);
            store.commit('setTimeout', setTimeout(() => {
              store.dispatch('refresh');
            }, optimalRefresh(store.state.auth)));

            return response;
          }).catch((error) => {
            store.commit('clearAuth');

            router.push({name: 'login'});

            return error;
          }).finally(() => {
            store.commit('loading', false);
          });

          store.commit('promise', promise);

          return promise;
        } else {
          store.commit('setTimeout', setTimeout(() => {
            store.dispatch('refresh');
          }, optimalRefresh(store.state.auth)));
        }
      }

      return Promise.resolve();
    },
    intended(store) {
      return router.push(store.state.intended || {name: 'index'});
    },
    logout(store) {
      return store.dispatch('request', {route: 'logout'}).finally(() => {
        store.commit('clearAuth');
        store.commit('clearCategories');
        store.commit('clearCourses');
        store.commit('clearLessons');
        store.commit('clearErrors');
      });
    },
    authLoader(store, flag) {
      if (true === flag) {
        store.commit('authProgressTimeout', null);
        store.commit('authProgress', true);
      } else {
        (new Promise((resolve) => {
          store.commit('authProgressTimeout', setTimeout(() => {
            resolve();
          }, 1000));
        })).then(() => {
          store.commit('authProgressTimeout', null);
          store.commit('authProgress', false);
        });
      }
    },
    getProfile(store) {
      return store.dispatch('request', {
        route: 'profile.show',
      }).then((response) => {
        store.commit('setProfile', response);

        return response;
      });
    },
  },
};
