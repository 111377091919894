<template>
  <div class="pm-card pm-card--course pm-card--course--wip mdc-card">
    <div class="pm-card__content mdc-theme--on-secondary">
      <img
        src="../../assets/wip.svg"
        alt="Další kurzy připravujeme"
        title="Další kurzy připravujeme"
      >
      <div class="mdc-typography--headline3">
        Další kurzy již brzy..
      </div>
      <div class="mdc-typography--body1">
        Právě pro Vás připravujeme další kurzy
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseWipCard',
};
</script>
