<template>
  <div class="pm-section-grid">
    <h2 class="pm-section-grid__header mdc-typography--headline3">
      {{ s.name }}
      <span
        v-if="s.is_new"
        class="pm-section-grid__tag mdc-typography--caption mdc-theme--secondary-bg mdc-theme--on-secondary"
      >new</span>
    </h2>
    <div class="pm-lessons-grid">
      <lesson-card
        v-for="(lesson, i) in s.lessons"
        :key="lesson.id"
        :lesson="lesson"
        :index="index + i + 1"
      />
    </div>
  </div>
</template>

<script>
import LessonCard from './LessonCard.vue';

export default {
  name: 'SectionLessons',
  components: {
    LessonCard,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return ({
      s: {...this.section},
    });
  },
  watch: {
    section(value) {
      this.s = {...value};
    },
  },
};
</script>
