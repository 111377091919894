<template>
  <form
    ref="form"
    class="pm-form mdc-layout-grid"
    @submit.prevent="onSubmit"
  >
    <div class="pm-form__title mdc-typography--headline6">
      Registrace
    </div>
    <div class="pm-form__form mdc-layout-grid__inner">
      <mdc-form-text
        type="text"
        name="first_name"
        required
        form="register"
        label="Jméno"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet"
      />
      <mdc-form-text
        type="text"
        name="last_name"
        required
        form="register"
        label="Příjmení"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet"
      />
      <mdc-form-text
        type="email"
        name="email"
        required
        form="register"
        label="E-mail"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
        @input="value => email = value"
      />
      <mdc-form-text
        type="password"
        name="password"
        label="Heslo"
        required
        form="register"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet"
      />
      <mdc-form-text
        type="password"
        name="password_confirmation"
        label="Heslo znovu"
        required
        form="register"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet"
      />
      <div>
        <a
          class="mdc-button mdc-button--outlined"
          :href="vop"
        >Podmínky</a>
      </div>
      <mdc-form-checkbox
        name="gdpr"
        label="Souhlasím s podmínkami"
        required
        form="register"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
      />
    </div>
    <div class="pm-form__actions pm-form__actions--right">
      <mdc-button
        type="submit"
        raised
        :disabled="loading"
        :pm="false"
      >
        Registrovat
      </mdc-button>
    </div>
    <div class="pm-form__actions">
      <mdc-link
        :to="{name: 'login'}"
        :pm="false"
      >
        Přihlásit se
      </mdc-link>
      <mdc-link
        :to="{name: 'forgot'}"
        :pm="false"
      >
        Zapomenuté heslo
      </mdc-link>
    </div>
  </form>
</template>

<script>
import MdcFormText from '../components/MdcFormText.vue';
import MdcButton from '../components/MdcButton.vue';
import MdcLink from '../components/MdcLink.vue';
import MdcFormCheckbox from '../components/MdcFormCheckbox.vue';

import vop from '../../assets/2020-08-18-Lingly-obchodni-podminky.pdf';

export default {
  name: 'Register',
  components: {
    MdcFormText,
    MdcButton,
    MdcLink,
    MdcFormCheckbox,
  },
  data() {
    return ({
      email: '',
      loading: false,
    });
  },
  computed: {
    vop() {
      return vop;
    },
  },
  mounted() {
    this.$store.commit('clearErrors');
  },
  methods: {
    onSubmit() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.$store.dispatch('authLoader', true);
      this.$store
          .dispatch('request', {route: 'register', data: this.$refs.form})
          .then(() => {
            this.$router.push({name: 'verify', params: {email: this.email}}).then(() => {
              this.$store.dispatch('info', 'Ověřovací email odeslán!');
            }).finally(() => {
              this.$store.dispatch('authLoader', false);
              this.loading = false;
            });
          }).catch(() => {
            this.$store.dispatch('authLoader', false);
            this.loading = false;
          });
    },
  },
};
</script>
