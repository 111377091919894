var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "ref", class: _vm.classes }, [
    _c("div", { staticClass: "mdc-snackbar__surface" }, [
      _c(
        "div",
        {
          staticClass: "mdc-snackbar__label",
          attrs: { role: "status", "aria-live": "polite" },
        },
        [_vm._v("\n      " + _vm._s(_vm.notification.text) + "\n    ")]
      ),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mdc-snackbar__actions" }, [
      _c(
        "button",
        {
          staticClass: "mdc-icon-button mdc-snackbar__dismiss material-icons",
          attrs: { type: "button" },
        },
        [_vm._v("\n        close\n      ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }