import Vue from 'vue';

export default {
  state: {
    showPremiumModal: false,
    key: Math.random(),
  },
  getters: {},
  mutations: {
    showPremiumModal(state) {
      Vue.set(state, 'key', Math.random());
      Vue.set(state, 'showPremiumModal', true);
    },
    hidePremiumModal(state) {
      Vue.set(state, 'showPremiumModal', false);
      Vue.set(state, 'key', Math.random());
    },
  },
  actions: {},
};
