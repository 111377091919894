var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "pm-card",
        "pm-card--course",
        "mdc-card",
        _vm.active ? "pm-card--course--active" : "",
      ],
    },
    [
      _c(
        "div",
        {
          ref: "primary",
          staticClass:
            "pm-card--course__primary-action mdc-card__primary-action",
          on: { click: _vm.onClick },
        },
        [
          _c("div", {
            staticClass:
              "pm-card--course__media mdc-card__media mdc-card__media--16-9",
            style: _vm.coverStyles,
          }),
          _vm._v(" "),
          _c("div", { staticClass: "pm-card__content" }, [
            _c(
              "div",
              { staticClass: "pm-card--course__progress" },
              [
                _c("mdc-progress", {
                  staticClass: "pm-card--course__progress__line",
                  attrs: { progress: _vm.calculatedProgress },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "pm-card--course__progress__count mdc-typography--button",
                  },
                  [
                    _c(
                      "span",
                      {
                        class: [
                          "pm-card--course__progress__from",
                          _vm.active
                            ? "mdc-theme--text-primary-on-light"
                            : "mdc-theme--text-hint-on-light",
                        ],
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.course.progress) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "mdc-theme--text-hint-on-light" },
                      [
                        _vm._v(
                          "\n            / " +
                            _vm._s(_vm.course.lessons_count) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "pm-card--course__text",
                  _vm.active
                    ? "mdc-theme--text-primary-on-light"
                    : "mdc-theme--text-hint-on-light",
                ],
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "mdc-typography--headline3 pm-card--course__name",
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.course.name) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.course.title
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "mdc-typography--body1 pm-card--course__title",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.course.title) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }