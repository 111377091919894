var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "pm-form mdc-layout-grid",
        staticStyle: { "padding-bottom": "0" },
      },
      [
        _c(
          "div",
          {
            staticClass: "mdc-typography--headline6",
            staticStyle: { "margin-bottom": "0.5em", "font-weight": "700" },
          },
          [
            _vm._v(
              "\n      Lingly " +
                _vm._s(
                  _vm.$store.state.auth.auth.premium ? "Premium" : "Free"
                ) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.$store.state.auth.auth.premium
          ? _c(
              "mdc-button",
              {
                staticClass: "pm-button--gradient",
                nativeOn: {
                  click: function ($event) {
                    return _vm.$store.commit("showPremiumModal")
                  },
                },
              },
              [_vm._v("\n      Získat PREMIUM\n    ")]
            )
          : _c(
              "div",
              {
                staticClass: "mdc-typography--body1",
                staticStyle: { "line-height": "1.5" },
              },
              [
                _c("div", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-right": "0.25em",
                        "font-weight": "700",
                      },
                    },
                    [_vm._v("Členství:")]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$store.state.auth.auth.premium.type == "M"
                            ? "Měsíční"
                            : _vm.$store.state.auth.auth.premium.type == "Q"
                            ? "Čtvrtletní"
                            : "Půlroční"
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-right": "0.25em",
                        "font-weight": "700",
                      },
                    },
                    [_vm._v("Od:")]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$store.state.auth.auth.premium.starts_at)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-right": "0.25em",
                        "font-weight": "700",
                      },
                    },
                    [_vm._v("Do:")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-right": "0.25em" } }, [
                    _vm._v(_vm._s(_vm.$store.state.auth.auth.premium.ends_at)),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "(" +
                        _vm._s(
                          _vm.$store.state.auth.auth.premium.ends_at_natural
                        ) +
                        ")"
                    ),
                  ]),
                ]),
              ]
            ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        ref: "form",
        staticClass: "pm-form mdc-layout-grid",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "pm-form__title mdc-typography--headline6" }, [
          _vm._v("\n      Úprava profilu\n    "),
        ]),
        _vm._v(" "),
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "pm-form__form mdc-layout-grid__inner" },
              [
                _c("mdc-form-text", {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet",
                  attrs: {
                    value: _vm.$store.state.auth.profile.first_name,
                    type: "text",
                    name: "first_name",
                    required: "",
                    form: "profile.update",
                    label: "Jméno",
                  },
                  on: {
                    input: function (value) {
                      return (_vm.first_name = value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("mdc-form-text", {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet",
                  attrs: {
                    value: _vm.$store.state.auth.profile.last_name,
                    type: "text",
                    name: "last_name",
                    required: "",
                    form: "profile.update",
                    label: "Příjmení",
                  },
                  on: {
                    input: function (value) {
                      return (_vm.last_name = value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("mdc-form-text", {
                  staticClass:
                    "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
                  attrs: {
                    value: _vm.$store.state.auth.profile.email,
                    type: "email",
                    name: "email",
                    required: "",
                    form: "profile.update",
                    label: "E-mail",
                  },
                  on: {
                    input: function (value) {
                      return (_vm.email = value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.email != _vm.$store.state.auth.profile.email
                  ? _c("mdc-form-text", {
                      staticClass:
                        "mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet",
                      attrs: {
                        type: "password",
                        name: "password_protected",
                        label: "Ověření heslem",
                        required: "",
                        form: "profile.update",
                      },
                      on: {
                        input: function (value) {
                          return (_vm.password_protected = value)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "pm-form__actions pm-form__actions--right" },
              [
                _c(
                  "mdc-button",
                  {
                    attrs: {
                      disabled: _vm.loading,
                      type: "submit",
                      raised: "",
                      pm: false,
                    },
                  },
                  [_vm._v("\n        Odeslat\n      ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }