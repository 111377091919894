var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "pm-card",
        "pm-card--course",
        "mdc-card",
        "pm-card--course--fake",
      ],
    },
    [
      _c(
        "div",
        {
          ref: "primary",
          staticClass:
            "pm-card--course__primary-action mdc-card__primary-action",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "pm-card--course__media mdc-card__media mdc-card__media--16-9",
            },
            [
              _c("div", { staticClass: "mdc-card__media-content" }, [
                _vm.randomBool
                  ? _c("img", {
                      staticClass: "pm-card--course__media__premium",
                      attrs: {
                        src: require("../../assets/course--premium.svg"),
                        alt: "Preiomý kurz",
                        title: "Premiový kurz",
                      },
                    })
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pm-card__content" }, [
            _c(
              "div",
              { staticClass: "pm-card--course__progress" },
              [
                _c("mdc-progress", {
                  staticClass: "pm-card--course__progress__line",
                  attrs: { progress: 0, indeterminate: "" },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "pm-card--course__progress__count mdc-typography--button",
                  },
                  [
                    _c(
                      "span",
                      {
                        class: [
                          "pm-card--course__progress__from",
                          "mdc-theme--text-hint-on-light",
                        ],
                      },
                      [_c("fake", { attrs: { length: 3 } })],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { class: ["pm-card--course__text"] }, [
              _c(
                "h2",
                {
                  staticClass:
                    "mdc-typography--headline3 pm-card--course__name",
                },
                [_c("fake", { attrs: { length: 13 } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mdc-typography--body1 pm-card--course__title" },
                [_c("fake", { attrs: { length: 27 } })],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }