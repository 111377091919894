<template>
  <div>
    <div
      ref="mdcField"
      class="mdc-form-field"
    >
      <div
        ref="mdcCheckbox"
        class="mdc-checkbox"
      >
        <input
          :id="'checkbox' + name"
          v-model="val"
          type="checkbox"
          class="mdc-checkbox__native-control"
          :name="name"
          :checked="val"
          :required="required"
        >
        <div class="mdc-checkbox__background">
          <svg
            class="mdc-checkbox__checkmark"
            viewBox="0 0 24 24"
          >
            <path
              class="mdc-checkbox__checkmark-path"
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"
            />
          </svg>
          <div class="mdc-checkbox__mixedmark" />
        </div>
        <div class="mdc-checkbox__ripple" />
      </div>
      <label :for="'checkbox' + name">{{ label || name }}</label>
    </div>
    <div class="pm-errors">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="pm-errors__error mdc-theme--error mdc-typography--caption"
      >
        <span class="pm-errors__error__icon material-icons">error</span> {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import {MDCFormField} from '@material/form-field';
import {MDCCheckbox} from '@material/checkbox';

export default {
  name: 'MdcFormCheckbox',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    form: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
      val: this.$props.value,
      mdcField: null,
      mdcCheckbox: null,
    });
  },
  computed: {
    errors() {
      const allErrors = this.$store.state.requests.errors;

      if (allErrors && allErrors[this.form]) {
        if (allErrors[this.form].errors && allErrors[this.form].errors[this.name]) {
          return allErrors[this.form].errors[this.name];
        }
      }

      return [];
    },
  },
  watch: {
    val() {
      this.$emit('input', this.val);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.mdcField = new MDCFormField(this.$refs.mdcField);
      this.mdcCheckbox = new MDCCheckbox(this.$refs.mdcCheckbox);
      this.mdcField.input = this.mdcCheckbox;
    });
  },
  destroyed() {
    if (this.mdcField) {
      this.mdcField = this.mdcField.destroy();
    }
    if (this.mdcCheckbox) {
      this.mdcCheckbox = this.mdcCheckbox.destroy();
    }
  },
};
</script>
