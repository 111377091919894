<template>
  <div
    ref="dialog"
    class="mdc-dialog pm-premium-modal"
  >
    <div class="pm-premium-modal__container mdc-dialog__container">
      <form
        class="mdc-dialog__surface"
        role="alertdialog"
        aria-modal="true"
        method="POST"
        @submit.prevent="handleSubmit"
      >
        <div />
        <div
          class="pm-premium-modal__content mdc-dialog__content"
        >
          <img
            src="../../assets/premium.svg"
            alt="Premium"
            title="Premium"
            class="pm-premium-modal__img"
          >
          <h2
            style="color: hsl(0, 0%, 0%);"
            class="mdc-typography--headline3"
          >
            Lingly Premium
          </h2>
          <p class="mdc-typography--caption">
            Zakoupením předplatného získáte přístup k veškerému obsahu v rámci Lingly a rozšířenou podporu od našich lektorů.
          </p>
          <div class="pm-premium-modal__options">
            <ul
              ref="list"
              class="pm-premium-modal__radio mdc-list"
            >
              <li
                :class="['pm-premium-modal__radio__item', 'mdc-list-item', selected === 'M' ? 'pm-premium-modal__radio__item--selected' : '']"
                tabindex="0"
              >
                <span class="mdc-list-item__graphic">
                  <div class="mdc-radio">
                    <input
                      id="monthly"
                      v-model="selected"
                      name="premium"
                      required
                      class="mdc-radio__native-control"
                      type="radio"
                      value="M"
                    >
                    <div class="mdc-radio__background">
                      <div class="mdc-radio__outer-circle" />
                      <div class="mdc-radio__inner-circle" />
                    </div>
                  </div>
                </span>
                <label
                  for="monthly"
                  class="pm-premium-modal__radio__label mdc-list-item__text"
                >
                  <div class="mdc-typography--button">Měsíční</div>
                  <div>
                    <span />
                    <span class="mdc-typography--button">149 Kč</span>
                  </div>
                </label>
              </li>
              <li
                :class="['pm-premium-modal__radio__item mdc-list-item', selected === 'Q' ? 'pm-premium-modal__radio__item--selected' : '']"
                tabindex="1"
              >
                <span class="mdc-list-item__graphic">
                  <div class="mdc-radio">
                    <input
                      id="quarterly"
                      v-model="selected"
                      name="premium"
                      required
                      class="mdc-radio__native-control"
                      type="radio"
                      value="Q"
                    >
                    <div class="mdc-radio__background">
                      <div class="mdc-radio__outer-circle" />
                      <div class="mdc-radio__inner-circle" />
                    </div>
                  </div>
                </span>
                <label
                  for="quarterly"
                  class="pm-premium-modal__radio__label mdc-list-item__text"
                >
                  <div class="mdc-typography--button">Čtvrtletní</div>
                  <div>
                    <span class="pm-premium-modal__tag">Sleva 10%</span>
                    <span class="mdc-typography--button">399 Kč</span>
                  </div>
                </label>
              </li>
              <li
                :class="['pm-premium-modal__radio__item mdc-list-item', selected === 'Y' ? 'pm-premium-modal__radio__item--selected' : '']"
                tabindex="2"
              >
                <span class="mdc-list-item__graphic">
                  <div class="mdc-radio">
                    <input
                      id="year"
                      v-model="selected"
                      name="premium"
                      required
                      class="mdc-radio__native-control"
                      type="radio"
                      value="Y"
                    >
                    <div class="mdc-radio__background">
                      <div class="mdc-radio__outer-circle" />
                      <div class="mdc-radio__inner-circle" />
                    </div>
                  </div>
                </span>
                <label
                  for="year"
                  class="pm-premium-modal__radio__label  mdc-list-item__text"
                >
                  <div class="mdc-typography--button">Roční</div>
                  <div>
                    <span class="pm-premium-modal__tag">Sleva 30%</span>
                    <span class="mdc-typography--button">1 249 Kč</span>
                  </div>
                </label>
              </li>
            </ul>
            <span class="mdc-typography--caption">Uvedené ceny jsou včetně DPH</span>
          </div>
        </div>
        <footer class="pm-premium-modal__actions mdc-dialog__actions">
          <button
            :disabled="selected === ''"
            type="submit"
            class="mdc-button mdc-button--raised"
          >
            <div class="mdc-button__ripple" />
            <span class="mdc-button__label">Zakoupit vybraný plán</span>
          </button>
        </footer>
      </form>
    </div>
    <div class="mdc-dialog__scrim" />
  </div>
</template>

<script>
import {MDCDialog} from '@material/dialog';
import {MDCList} from '@material/list';

export default {
  name: 'PremiumModal',
  data() {
    return {
      mdc: null,
      list: null,
      selected: '',
      loading: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.mdc = new MDCDialog(this.$refs.dialog);

      this.list = new MDCList(this.$refs.list);

      this.mdc.listen('MDCDialog:closed', (event) => {
        this.$store.commit('hidePremiumModal');
      });

      this.mdc.listen('MDCDialog:opened', (event) => {
        this.list.layout();
      });

      this.mdc.open();
    });
  },
  beforeDestroy() {
    if (this.mdc) {
      this.mdc = this.mdc.close();
    }
  },
  destroyed() {
    if (this.mdc) {
      this.mdc = this.mdc.destroy();
    }

    if (this.list) {
      this.list = this.list.destroy();
    }
  },
  methods: {
    handleSubmit() {
      if (this.loading) {
        return;
      }

      if (['M', 'Q', 'Y'].indexOf(this.selected) === -1) {
        return;
      }

      const data = {
        type: this.selected,
        redirect: this.$route.fullPath,
      };

      this.loading = true;

      this.$store
          .dispatch('request', {route: 'premiums.store', data: data})
          .then((response) => {
            window.open(response.json.data.url);
          }).finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
