import store from '../store';

export function shouldRefresh(accessed, time) {
  return ((accessed === null || accessed === undefined) || ((Date.now() - accessed) > time));
}

export default function request(url, method, data, params, guest = false) {
  let body = null;

  const headers = {
    'Accept': 'application/json',
    'X-API-Key': 'LINGLY',
  };

  if (method && method !== 'GET') {
    if (data instanceof HTMLElement) {
      body = new FormData(data);
    } else if ('string' === typeof data) {
      body = data;
      headers['Content-Type'] = 'application/json';
    } else {
      body = JSON.stringify(data);
      headers['Content-Type'] = 'application/json';
    }
  }

  if (!guest && store.state.auth.auth && store.state.auth.auth.access_token) {
    headers['Authorization'] = 'Bearer ' + store.state.auth.auth.access_token;
  }

  for (const key in params) {
    if ('string' === typeof key && ('string' === typeof params[key] || 'number' === typeof params[key])) {
      url = url.replace(':' + key, String(params[key]));
    }
  }

  return fetch(url, {
    method: method || 'GET',
    headers: headers,
    body: body,
  }).then((response) => {
    const status = {
      ok: response.ok,
      status: response.status,
      text: response.statusText,
    };

    return response.json().then((json) => {
      return {status: status, json: json};
    }, () => {
      status.status = 204;

      return {status: status, json: null};
    });
  }, (error) => {
    throw error;
  });
}
