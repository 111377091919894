var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pm-auth-grid" }, [
    _c(
      "div",
      { staticClass: "pm-card pm-card--auth mdc-card" },
      [
        _c("mdc-progress", {
          staticClass: "pm-auth-grid__progress",
          attrs: {
            indeterminate: _vm.$store.state.auth.authProgress,
            progress: 0,
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pm-card__content" },
          [
            _c(
              "transition",
              { attrs: { name: "pm-router", mode: "out-in", appear: "" } },
              [_c("router-view")],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }