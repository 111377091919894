var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pm-section-grid" }, [
    _c(
      "h2",
      { staticClass: "pm-section-grid__header mdc-typography--headline3" },
      [
        _vm._v("\n    " + _vm._s(_vm.s.name) + "\n    "),
        _vm.s.is_new
          ? _c(
              "span",
              {
                staticClass:
                  "pm-section-grid__tag mdc-typography--caption mdc-theme--secondary-bg mdc-theme--on-secondary",
              },
              [_vm._v("new")]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pm-lessons-grid" },
      _vm._l(_vm.s.lessons, function (lesson, i) {
        return _c("lesson-card", {
          key: lesson.id,
          attrs: { lesson: lesson, index: _vm.index + i + 1 },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }