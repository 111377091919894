<template>
  <div>
    <div
      class="pm-form mdc-layout-grid"
      style="padding-bottom: 0;"
    >
      <div
        class="mdc-typography--headline6"
        style=" margin-bottom: 0.5em; font-weight: 700;"
      >
        Lingly {{ $store.state.auth.auth.premium ? 'Premium' : 'Free' }}
      </div>
      <mdc-button
        v-if="!$store.state.auth.auth.premium"
        class="pm-button--gradient"
        @click.native="$store.commit('showPremiumModal')"
      >
        Získat PREMIUM
      </mdc-button>
      <div
        v-else
        class="mdc-typography--body1"
        style="line-height: 1.5;"
      >
        <div>
          <span style=" margin-right: 0.25em; font-weight: 700;">Členství:</span>
          <span>
            {{ $store.state.auth.auth.premium.type == 'M' ? 'Měsíční' : ( $store.state.auth.auth.premium.type == 'Q' ? 'Čtvrtletní' : 'Půlroční') }}
          </span>
        </div>
        <div>
          <span style=" margin-right: 0.25em; font-weight: 700;">Od:</span>
          <span>{{ $store.state.auth.auth.premium.starts_at }}</span>
        </div>
        <div>
          <span style=" margin-right: 0.25em; font-weight: 700;">Do:</span>
          <span style="margin-right: 0.25em;">{{ $store.state.auth.auth.premium.ends_at }}</span>
          <span>({{ $store.state.auth.auth.premium.ends_at_natural }})</span>
        </div>
      </div>
    </div>
    <form
      ref="form"
      class="pm-form mdc-layout-grid"
      @submit.prevent="onSubmit"
    >
      <div class="pm-form__title mdc-typography--headline6">
        Úprava profilu
      </div>
      <div
        v-if="loaded"
        class="pm-form__form mdc-layout-grid__inner"
      >
        <mdc-form-text
          :value="$store.state.auth.profile.first_name"
          type="text"
          name="first_name"
          required
          form="profile.update"
          label="Jméno"
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet"
          @input="value => first_name = value"
        />
        <mdc-form-text
          :value="$store.state.auth.profile.last_name"
          type="text"
          name="last_name"
          required
          form="profile.update"
          label="Příjmení"
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet"
          @input="value => last_name = value"
        />
        <mdc-form-text
          :value="$store.state.auth.profile.email"
          type="email"
          name="email"
          required
          form="profile.update"
          label="E-mail"
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
          @input="value => email = value"
        />
        <mdc-form-text
          v-if="email != $store.state.auth.profile.email"
          type="password"
          name="password_protected"
          label="Ověření heslem"
          required
          form="profile.update"
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-8-tablet"
          @input="value => password_protected = value"
        />
      </div>
      <div
        v-if="loaded"
        class="pm-form__actions pm-form__actions--right"
      >
        <mdc-button
          :disabled="loading"
          type="submit"
          raised
          :pm="false"
        >
          Odeslat
        </mdc-button>
      </div>
    </form>
  </div>
</template>

<script>
import MdcFormText from '../components/MdcFormText.vue';
import MdcButton from '../components/MdcButton.vue';

export default {
  name: 'Profile',
  components: {
    MdcFormText,
    MdcButton,
  },
  data() {
    return ({
      loading: true,
      loaded: false,
      email: '',
      first_name: '',
      last_name: '',
      password_protected: '',
    });
  },
  mounted() {
    this.$store.commit('clearErrors');
  },
  created() {
    this.$store.dispatch('authLoader', true);
    this.$store.dispatch('getProfile').then(() => {
      this.email = this.$store.state.auth.profile.email;
      this.first_name = this.$store.state.auth.profile.first_name;
      this.last_name = this.$store.state.auth.profile.last_name;
      this.loaded = true;
      this.loading = false;
    }, () => {}).finally(() => {
      this.$store.dispatch('authLoader', false);
    });
  },
  methods: {
    getData() {
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
      };

      if (this.email != this.$store.state.auth.profile.email) {
        data.email = this.email;
        data.password_protected = this.password_protected;
      }

      return data;
    },
    onSubmit() {
      if (!this.loaded || this.loading) {
        return;
      }

      this.loading = true;
      this.$store.dispatch('authLoader', true);
      this.$store
          .dispatch('request', {route: 'profile.update', data: this.getData()})
          .then((response) => {
            this.$store.commit('setProfile', response);
            this.$store.dispatch('info', 'Profil úspěšně aktualizován');
          }).finally(() => {
            this.$store.dispatch('authLoader', false);
            this.loading = false;
          });
    },
  },
};
</script>
