<template>
  <div :class="['pm-card', 'pm-card--course', 'mdc-card', 'pm-card--course--fake']">
    <div
      ref="primary"
      class="pm-card--course__primary-action mdc-card__primary-action"
    >
      <div class="pm-card--course__media mdc-card__media mdc-card__media--16-9">
        <div class="mdc-card__media-content">
          <img
            v-if="randomBool"
            src="../../assets/course--premium.svg"
            alt="Preiomý kurz"
            title="Premiový kurz"
            class="pm-card--course__media__premium"
          >
        </div>
      </div>
      <div class="pm-card__content">
        <div class="pm-card--course__progress">
          <mdc-progress
            :progress="0"
            indeterminate
            class="pm-card--course__progress__line"
          />
          <div class="pm-card--course__progress__count mdc-typography--button">
            <span :class="['pm-card--course__progress__from', 'mdc-theme--text-hint-on-light']">
              <fake :length="3" />
            </span>
          </div>
        </div>
        <div :class="['pm-card--course__text']">
          <h2 class="mdc-typography--headline3 pm-card--course__name">
            <fake :length="13" />
          </h2>
          <div
            class="mdc-typography--body1 pm-card--course__title"
          >
            <fake :length="27" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MdcProgress from './MdcProgress.vue';
import Fake from './Fake.vue';

export default {
  name: 'CourseLoadingCard',
  components: {
    MdcProgress,
    Fake,
  },
  data() {
    return ({
      randomBool: Math.random() >= 0.5,
    });
  },
};
</script>
