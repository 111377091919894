<template>
  <div
    class="pm-card pm-card--welcome mdc-card"
  >
    <div
      class="pm-card__content pm-card--welcome__background"
      :style="{'background-image': `url(${welcomeBackCard})`}"
    >
      <div class="pm-card--welcome__intro">
        <img
          src="../../assets/welcome.svg"
          title="Vítejte"
          alt="Vítejte"
        >
        <div class="mdc-theme--on-primary pm-card--welcome__intro__text">
          <span class="mdc-typography--headline2"><span style="font-weight: 400;">Vítejte zpět{{ $store.state.auth.auth.vocativ ? ', ' + $store.state.auth.auth.vocativ + '!' : '!' }}</span></span>
          <span class="mdc-typography--button mdc-theme--text-secondary-on-dark">Chcete získat přístup do všech kurzů? Vyzkoušejte Lingly Premium!</span>
        </div>
      </div>
      <mdc-button
        class="pm-button--white"
        @click.native="$store.commit('showPremiumModal')"
      >
        Vyzkoušet PREMIUM
      </mdc-button>
    </div>
  </div>
</template>

<script>
import MdcButton from './MdcButton.vue';

import welcomeBackCard from '../../assets/welcome_card.svg';

export default {
  name: 'WelcomeBackCard',
  components: {
    MdcButton,
  },
  computed: {
    welcomeBackCard() {
      return welcomeBackCard;
    },
  },
};
</script>
