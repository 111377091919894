var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("audio", {
      ref: "audio_answer",
      attrs: { src: _vm.question.audio_answer },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mdc-theme--text-hint-on-light pm-question__ask" },
      [_vm._v("\n    " + _vm._s(_vm.ask) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.audio
          ? [
              _vm.transcript
                ? _c("div", { staticClass: "pm-question__challenge" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.question.challenge) + "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "mdc-button",
                {
                  attrs: { pm: false, "pre-icon": "volume_up" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.playQuestion.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v("\n        Přehrát\n        "),
                  _c("audio", {
                    ref: "audio_question",
                    attrs: { src: _vm.question.audio_question },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.question.image
                ? _c("div", { staticStyle: { "margin-top": "2rem" } }, [
                    _c("img", {
                      staticClass: "pm-question__image",
                      attrs: {
                        src: _vm.question.image,
                        alt: _vm.question.challenge,
                        title: _vm.question.challenge,
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          : _vm.image
          ? [
              _c("div", { staticClass: "pm-question__challenge" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.question.challenge) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "pm-question__image",
                attrs: {
                  src: _vm.question.image,
                  alt: _vm.question.challenge,
                  title: _vm.question.challenge,
                },
              }),
            ]
          : _vm.input
          ? [
              _c("div", { staticClass: "pm-question__challenge" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.question.challenge) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  ref: "formQuestion",
                  staticClass: "pm-form mdc-layout-grid",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.confirmAnswer.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("mdc-form-text", {
                    staticClass:
                      "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
                    attrs: {
                      type: "text",
                      name: "inputAnswer",
                      label: "Odpověď",
                      required: "",
                      form: "formQuestion",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pm-form__actions pm-form__actions--right" },
                    [
                      _c(
                        "mdc-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { type: "submit", raised: "" },
                        },
                        [_vm._v("\n            zkontrolovat\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "pm-button mdc-button mdc-ripple-upgraded",
                  staticStyle: { width: "100%" },
                  on: {
                    click: function ($event) {
                      return _vm.prompter()
                    },
                  },
                },
                [_vm._v("\n        ? nápověda\n      ")]
              ),
              _vm._v(" "),
              _vm.question.image
                ? _c("div", { staticStyle: { "margin-top": "2rem" } }, [
                    _c("img", {
                      staticClass: "pm-question__image",
                      attrs: {
                        src: _vm.question.image,
                        alt: _vm.question.challenge,
                        title: _vm.question.challenge,
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          : _vm.image
          ? [
              _c("div", { staticClass: "pm-question__challenge" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.question.challenge) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "pm-question__image",
                attrs: {
                  src: _vm.question.image,
                  alt: _vm.question.challenge,
                  title: _vm.question.challenge,
                },
              }),
            ]
          : _vm.cloze
          ? [
              _c(
                "div",
                { staticClass: "pm-question__challenge" },
                [
                  _c("div", { staticStyle: { display: "inline-block" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.clozeBefore) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "transition",
                    {
                      attrs: { name: "pm-router", mode: "out-in", appear: "" },
                    },
                    [
                      false !== _vm.selected
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pm-question__fake-cloze pm-question__fake-cloze--show",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.clozeInner) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "pm-question__fake-cloze" },
                            [
                              _vm._v(
                                "\n             \n             \n             \n          "
                              ),
                            ]
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { display: "inline-block" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.clozeAfter) + "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.question.image
                ? _c("img", {
                    staticClass: "pm-question__image",
                    attrs: {
                      src: _vm.question.image,
                      alt: _vm.question.challenge,
                      title: _vm.question.challenge,
                    },
                  })
                : _vm._e(),
            ]
          : [
              _c("div", { staticClass: "pm-question__challenge" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.question.challenge) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _vm.question.image
                ? _c("img", {
                    staticClass: "pm-question__image",
                    attrs: {
                      src: _vm.question.image,
                      alt: _vm.question.challenge,
                      title: _vm.question.challenge,
                    },
                  })
                : _vm._e(),
            ],
      ],
      2
    ),
    _vm._v(" "),
    !_vm.input
      ? _c(
          "div",
          { staticClass: "pm-question__answers" },
          _vm._l(_vm.randomAnswers, function (answer) {
            return _c(
              "mdc-button",
              {
                key: answer.index,
                class: {
                  "pm-question__answer": true,
                  "pm-button--transition": true,
                  "pm-game-button": true,
                  "pm-game-button--success": answer.index === 0,
                  "pm-game-button--error": answer.index !== 0,
                },
                attrs: {
                  instantiate: false,
                  disabled: false !== _vm.selected && answer.index !== 0,
                  "data-selected": answer.index === _vm.selected,
                  "data-answered": false !== _vm.selected,
                  outlined: "",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClick(answer.index)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(answer.answer) + "\n    ")]
            )
          }),
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.question.english_translation || _vm.question.czech_translation
      ? _c(
          "div",
          { staticClass: "pm-question__translations" },
          [
            _c("transition", { attrs: { name: "pm-router", appear: "" } }, [
              _vm.question.english_translation && false !== _vm.selected
                ? _c("div", { staticClass: "pm-question__translation" }, [
                    _c("img", {
                      staticClass: "pm-question__translation-flag",
                      attrs: {
                        src: require("../../assets/en.svg"),
                        alt: "Anglický překlad",
                        title: "Anglický překlad",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "mdc-typography--button pm-question__translation-text",
                      },
                      [_vm._v(_vm._s(_vm.question.english_translation))]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "pm-router", appear: "" } }, [
              _vm.question.czech_translation && false !== _vm.selected
                ? _c("div", { staticClass: "pm-question__translation" }, [
                    _c("img", {
                      staticClass: "pm-question__translation-flag",
                      attrs: {
                        src: require("../../assets/cz.svg"),
                        alt: "Český překlad",
                        title: "Český překlad",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "mdc-typography--button pm-question__translation-text",
                      },
                      [_vm._v(_vm._s(_vm.question.czech_translation))]
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }