var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mdc-card pm-card--lesson",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          class: {
            "mdc-card__media": true,
            "mdc-card__media--square": true,
            "mdc-card__media--square--active": _vm.l.stars > 0,
            "mdc-card__media--square--locked": !_vm.l.is_available,
          },
          style: { "--rotation": _vm.index * 20 + "deg" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "mdc-theme--on-primary mdc-card__media-content pm-card--lesson__index",
            },
            [_vm._v("\n      " + _vm._s(_vm.index) + "\n    ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pm-card--lesson__inner" }, [
        _c(
          "h3",
          {
            staticClass: "pm-card--lesson__name",
            class: { "pm-card--lesson__name--locked": !_vm.l.is_available },
          },
          [_vm._v("\n      " + _vm._s(_vm.l.name) + "\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "pm-card--lesson__actions" }, [
          _c(
            "div",
            {
              staticClass:
                "pm-card--lesson__levels mdc-typography--button mdc-theme--text-hint-on-light",
            },
            [
              _c(
                "div",
                {
                  class: {
                    "pm-card--lesson__level": true,
                    "pm-card--lesson__level--active": _vm.l.stars > 0,
                    "mdc-theme--text-primary-on-light": _vm.l.stars > 0,
                  },
                },
                [
                  _c("span", { staticClass: "pm-card--lesson__level__name" }, [
                    _vm._v("Level 1"),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "pm-card--lesson__level__indicator",
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "pm-card--lesson__level": true,
                    "pm-card--lesson__level--active": _vm.l.stars > 1,
                    "mdc-theme--text-primary-on-light": _vm.l.stars > 1,
                  },
                },
                [
                  _c("span", { staticClass: "pm-card--lesson__level__name" }, [
                    _vm._v("Level 2"),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "pm-card--lesson__level__indicator",
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "pm-card--lesson__level": true,
                    "pm-card--lesson__level--active": _vm.l.stars > 2,
                    "mdc-theme--text-primary-on-light": _vm.l.stars > 2,
                  },
                },
                [
                  _c("span", { staticClass: "pm-card--lesson__level__name" }, [
                    _vm._v("Level 3"),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "pm-card--lesson__level__indicator",
                  }),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }