import Vue from 'vue';
import {shouldRefresh} from '../api';

export default {
  state: {
    courses: {},
    loading: false,
  },
  getters: {
    courses(_, __, state) {
      return state.categories.categories.map((category) => {
        return category.courses;
      }).flat();
    },
    course(state) {
      return (id) => {
        return state.courses[id];
      };
    },
  },
  mutations: {
    setCourse(state, course) {
      course.accessed = Date.now();

      Vue.set(state.courses, course.id, course);
    },
    loading(state, flag) {
      Vue.set(state, 'loading', flag);
    },
    clearCourses(state) {
      Vue.set(state, 'courses', {});
    },
  },
  actions: {
    getCourse(store, payload) {
      if (payload.force || (!store.getters.course(payload.id) || shouldRefresh(store.getters.course(payload.id).accessed, 10*60*1000))) {
        store.commit('loading', true);

        return store.dispatch('request', {
          route: 'courses.show',
          params: {id: payload.id},
        }).then((response) => {
          store.commit('setCourse', response.json);

          return response;
        }).finally(() => {
          store.commit('loading', false);
        });
      }
    },
  },
};
