var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pm-explode-animation" },
    [
      _vm._l(40, function (index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "material-icons pm-explode-animation__alternate",
            style: {
              "--scale": Math.max(0.5, Math.random() * 5),
              "--left": String(Math.round(Math.random() * 100)) + "%",
              "--top": String(Math.round(Math.random() * 100)) + "%",
              color:
                "rgb(" +
                Math.random() * 255 +
                ", " +
                Math.random() * 255 +
                ", " +
                Math.random() * 255 +
                ")",
              "animation-delay": String(Math.random() * 20) + "s",
            },
          },
          [_vm._v("\n    " + _vm._s(_vm.alternate) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "material-icons": true,
            "pm-explode-animation__main": true,
            "mdc-theme--primary": _vm.success,
            "mdc-theme--secondary": !_vm.success,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.main) + "\n  ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }