<template>
  <header
    ref="mdcHeader"
    class="pm-top-app-bar mdc-top-app-bar mdc-top-app-bar--fixed"
  >
    <div class="mdc-layout-grid pm-top-app-bar__layout-grid mdc-top-app-bar__row">
      <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
        <router-link :to="{name: 'landing'}">
          <img
            src="../../assets/logo.png"
            alt="Lingly logo"
            title="Lingly logo"
            class="pm-top-app-bar__logo"
          >
        </router-link>
      </section>
      <section
        class="pm-only-mobile pm-top-app-bar__section--align-end mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
        role="toolbar"
      >
        <template v-if="$store.state.auth.auth">
          <mdc-menu>
            <template v-slot:button>
              <button
                class="material-icons mdc-icon-button mdc-top-app-bar__action-item"
              >
                menu
              </button>
            </template>
            <router-link
              tag="li"
              class="mdc-list-item"
              role="menuitem"
              :to="{name: 'index'}"
            >
              <span class="mdc-list-item__text">Přehled kurzů</span>
            </router-link>
            <router-link
              tag="li"
              class="mdc-list-item"
              role="menuitem"
              :to="{name: 'contact-form'}"
            >
              <span class="mdc-list-item__text">Ptejte se!</span>
            </router-link>
            <li
              v-if="!$store.state.auth.auth.premium"
              class="mdc-list-item"
              role="menuitem"
              @click="$store.commit('showPremiumModal')"
            >
              <span class="mdc-list-item__text">Získat PREMIUM</span>
            </li>
            <router-link
              tag="li"
              class="mdc-list-item"
              role="menuitem"
              :to="{name: 'profile'}"
            >
              <span class="mdc-list-item__text">Profil</span>
            </router-link>
            <router-link
              tag="li"
              class="mdc-list-item"
              role="menuitem"
              :to="{name: 'logout'}"
            >
              <span class="mdc-list-item__text">Odhlásit se</span>
            </router-link>
          </mdc-menu>
        </template>
        <template v-else>
          <mdc-menu>
            <template v-slot:button>
              <button
                class="material-icons mdc-icon-button mdc-top-app-bar__action-item"
              >
                menu
              </button>
            </template>
            <router-link
              tag="li"
              class="mdc-list-item"
              role="menuitem"
              :to="{name: 'login'}"
            >
              <span class="mdc-list-item__text">Přihlásit se</span>
            </router-link>
            <router-link
              tag="li"
              class="mdc-list-item"
              role="menuitem"
              :to="{name: 'register'}"
            >
              <span class="mdc-list-item__text">Začít zdarma</span>
            </router-link>
          </mdc-menu>
        </template>
      </section>
      <section
        class="pm-not-mobile pm-top-app-bar__section--align-end mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
        role="toolbar"
      >
        <template v-if="$store.state.auth.auth">
          <mdc-link
            :to="{name: 'index'}"
          >
            Přehled kurzů
          </mdc-link>
          <mdc-link
            :to="{name: 'contact-form'}"
          >
            Ptejte se!
          </mdc-link>
          <mdc-button
            v-if="!$store.state.auth.auth.premium"
            class="pm-button--gradient"
            @click.native="$store.commit('showPremiumModal')"
          >
            Získat PREMIUM
          </mdc-button>
          <mdc-button
            v-else
            class="pm-button--orange"
            @click.native="$router.push({name: 'profile'})"
          >
            LINGLY PREMIUM
          </mdc-button>
          <mdc-menu class="pm-top-app-bar__profile">
            <template v-slot:button>
              <button
                class="pm-icon-button mdc-icon-button mdc-top-app-bar__action-item"
              >
                <img
                  src="../../assets/profile.svg"
                  alt="Profil"
                  title="Profil"
                  class="mdc-icon-button__icon"
                >
              </button>
            </template>
            <router-link
              tag="li"
              class="mdc-list-item"
              role="menuitem"
              :to="{name: 'profile'}"
            >
              <span class="mdc-list-item__text">Profil</span>
            </router-link>
            <router-link
              tag="li"
              class="mdc-list-item"
              role="menuitem"
              :to="{name: 'logout'}"
            >
              <span class="mdc-list-item__text">Odhlásit se</span>
            </router-link>
          </mdc-menu>
        </template>
        <template v-else>
          <mdc-link
            dark
            :to="{name: 'login'}"
          >
            Přihlásit se
          </mdc-link>
          <mdc-link
            dark
            :to="{name: 'register'}"
          >
            Začít zdarma
          </mdc-link>
        </template>
      </section>
    </div>
  </header>
</template>

<script>
import {MDCTopAppBar} from '@material/top-app-bar';

import MdcButton from './MdcButton.vue';
import MdcLink from './MdcLink.vue';
import MdcMenu from './MdcMenu.vue';

export default {
  name: 'MdcHeader',
  components: {
    MdcButton,
    MdcLink,
    MdcMenu,
  },
  data() {
    return ({
      mdcHeader: null,
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.mdcHeader = new MDCTopAppBar(this.$refs.mdcHeader);
    });
  },
  destroyed() {
    if (this.mdcHeader) {
      this.mdcHeader = this.mdcHeader.destroy();
    }
  },
};
</script>
