import Vue from 'vue';

import routes from '../api/routes';
import request from '../api';

export default {
  state: {
    errors: {},
  },
  getters: {},
  mutations: {
    setErrors(state, payload) {
      Vue.set(state.errors, payload.route, payload.response.json);
    },
    unsetErrors(state, route) {
      Vue.delete(state.errors, route);
    },
    clearErrors(state) {
      Vue.set(state, 'errors', {});
    },
  },
  actions: {
    async request(store, payload) {
      const url = payload.url || '/api/v1' + routes[payload.route].url;
      const method = payload.method || routes[payload.route].method || 'GET';

      return request(url, method, payload.data || null, payload.params || {}, routes[payload.route].guest || false)
          .then((response) => {
            if (response.status.ok) {
            // resolve

              store.commit('unsetErrors', payload.route);

              return Promise.resolve(response);
            } else {
            // reject

              if (response.status.status === 422) {
                store.commit('setErrors', {
                  route: payload.route,
                  response: response,
                });
              } else {
                store.commit('unsetErrors', payload.route);
              }

              if (response.status.status === 401 && store.rootState.auth.auth && (!payload.route || (payload.route !== 'refresh'))) {
                if (store.rootState.auth.loading && null !== store.rootState.auth.promise) {
                  return store.rootState.auth.promise.then(() => {
                    return store.dispatch('request', payload);
                  });
                } else {
                  return store.dispatch('refresh', {force: true}).then(() => {
                    return store.dispatch('request', payload);
                  });
                }
              }

              return Promise.reject(response);
            }
          }, (error) => {
            store.dispatch('error', 'Chyba připojení!');

            throw error;
          });
    },
  },
};
