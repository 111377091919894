var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mdc-menu-surface--anchor" }, [
    _c("div", { on: { click: _vm.toggle } }, [_vm._t("button")], 2),
    _vm._v(" "),
    _c("div", { ref: "mdcMenu", staticClass: "mdc-menu mdc-menu-surface" }, [
      _c(
        "ul",
        {
          staticClass: "mdc-list",
          attrs: {
            role: "menu",
            "aria-hidden": "true",
            "aria-orientation": "vertical",
            tabindex: "-1",
          },
        },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }