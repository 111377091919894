var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      class: {
        "pm-router-link": true,
        "pm-router-link--dark": _vm.dark ? true : false,
      },
      attrs: {
        to: _vm.to,
        "exact-active-class": "pm-router-link-exact-active",
      },
    },
    [
      _c(
        "mdc-button",
        {
          attrs: {
            instantiate: _vm.instantiate,
            "pre-icon": _vm.preIcon,
            "post-icon": _vm.postIcon,
            pm: _vm.pm,
            raised: _vm.raised,
            unelevated: _vm.unelevated,
            outlined: _vm.outlined,
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }