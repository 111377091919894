<template>
  <form
    v-if="$route.query.token && $route.query.email"
    ref="form"
    class="pm-form mdc-layout-grid"
    @submit.prevent="onSubmit"
  >
    <div class="mdc-typography--headline6 pm-form__title">
      Změna hesla
    </div>
    <div class="pm-form__form mdc-layout-grid__inner">
      <mdc-form-text
        v-show="false"
        type="hidden"
        name="token"
        required
        form="change"
        :value="$route.query.token"
      />
      <mdc-form-text
        v-show="false"
        type="hidden"
        name="email"
        required
        form="change"
        :value="$route.query.email"
      />
      <mdc-form-text
        type="password"
        name="password"
        required
        form="change"
        label="Nové heslo"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
      />
      <mdc-form-text
        type="password"
        name="password_confirmation"
        required
        form="change"
        label="Nové heslo znovu"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
      />
    </div>
    <div class="pm-form__actions pm-form__actions--right">
      <mdc-button
        type="submit"
        raised
        :disabled="loading"
        :pm="false"
      >
        Změnit heslo
      </mdc-button>
    </div>
    <div class="pm-form__actions">
      <mdc-link
        :to="{name: 'register'}"
        :pm="false"
      >
        Registrace
      </mdc-link>
      <mdc-link
        :to="{name: 'login'}"
        :pm="false"
      >
        Přihlásit se
      </mdc-link>
    </div>
  </form>
  <div
    v-else
    class="pm-form mdc-layout-grid"
  >
    <div class="mdc-typography--headline6 pm-form__title">
      Zkontrolujte váš email
    </div>
    <div class="pm-form__actions">
      <mdc-link
        :to="{name: 'register'}"
        :pm="false"
      >
        Registrace
      </mdc-link>
      <mdc-link
        :to="{name: 'login'}"
        :pm="false"
      >
        Přihlásit se
      </mdc-link>
    </div>
  </div>
</template>

<script>
import MdcFormText from '../components/MdcFormText.vue';
import MdcButton from '../components/MdcButton.vue';
import MdcLink from '../components/MdcLink.vue';

export default {
  name: 'Change',
  components: {
    MdcFormText,
    MdcButton,
    MdcLink,
  },
  data() {
    return ({
      loading: false,
    });
  },
  created() {
    if (!(this.$route.query.token && this.$route.query.email) && !(this.$route.params.sent)) {
      this.$store.dispatch('authLoader', true);
      this.$router.push({name: 'forgot'}).finally(() => {
        this.$store.dispatch('authLoader', false);
      });
    }
  },
  mounted() {
    this.$store.commit('clearErrors');
  },
  methods: {
    onSubmit() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.$store.dispatch('authLoader', true);
      this.$store
          .dispatch('request', {route: 'change', data: this.$refs.form})
          .then((response) => {
            this.$store.commit('setAuth', response.json);
            this.$store.dispatch('refresh');

            this.$router.push({name: 'index'}).then(() => {
              this.$store.dispatch('info', 'Heslo změněno!');
            }).finally(() => {
              this.$store.dispatch('authLoader', false);
              this.loading = false;
            });
          }).catch(() => {
            this.$store.dispatch('authLoader', false);
            this.loading = false;
          });
    },
  },
};
</script>
