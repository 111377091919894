export default [
  {
    path: '/login',
    redirect: {name: 'login'},
  },
  {
    path: '/register',
    redirect: {name: 'register'},
  },
];
