<template>
  <div class="pm-section-grid">
    <h2 class="mdc-typography--headline3">
      <fake :length="13" />
    </h2>
    <div class="pm-lessons-grid">
      <lesson-card-fake
        v-for="i in 12"
        :key="i"
      />
    </div>
  </div>
</template>

<script>
import LessonCardFake from './LessonCardFake.vue';
import Fake from './Fake.vue';

export default {
  name: 'SectionLessonsFake',
  components: {
    LessonCardFake,
    Fake,
  },
};
</script>
