export default {
  state: {
    notifications: [],
  },
  getters: {},
  mutations: {
    addNotification(state, notification) {
      const type = notification.type || 'info';
      const text = notification.text || '';
      const time = notification.time || 5000;

      // if previous same delete insert this
      const same = state.notifications.filter((el) => {
        return el.type === type && el.text === text && el.time === time;
      });

      same.forEach((el) => {
        state.notifications.splice(state.notifications.indexOf(el), 1);
      });

      state.notifications.push({
        id: String(Date.now()) + type + text + String(time),
        type: type,
        text: text,
        time: time,
      });
    },
    removeNotification(state, notification) {
      const index = state.notifications.indexOf(notification);

      if (index >= 0) {
        state.notifications.splice(index, 1);
      }
    },
  },
  actions: {
    notify(store, notification) {
      store.commit('addNotification', notification);
    },
    info(store, text) {
      store.commit('addNotification', {text: text});
    },
    error(store, error) {
      store.commit('addNotification', {text: error, type: 'error'});
    },
  },
};
