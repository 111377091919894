<template>
  <div :class="['pm-card', 'pm-card--course', 'mdc-card', active ? 'pm-card--course--active' : '']">
    <div
      ref="primary"
      class="pm-card--course__primary-action mdc-card__primary-action"
      @click="onClick"
    >
      <div
        class="pm-card--course__media mdc-card__media mdc-card__media--16-9"
        :style="coverStyles"
      />
      <div class="pm-card__content">
        <div class="pm-card--course__progress">
          <mdc-progress
            :progress="calculatedProgress"
            class="pm-card--course__progress__line"
          />
          <div class="pm-card--course__progress__count mdc-typography--button">
            <span :class="['pm-card--course__progress__from', active ? 'mdc-theme--text-primary-on-light' : 'mdc-theme--text-hint-on-light']">
              {{ course.progress }}
            </span>
            <span class="mdc-theme--text-hint-on-light">
              / {{ course.lessons_count }}
            </span>
          </div>
        </div>
        <div :class="['pm-card--course__text', active ? 'mdc-theme--text-primary-on-light' : 'mdc-theme--text-hint-on-light']">
          <h2 class="mdc-typography--headline3 pm-card--course__name">
            {{ course.name }}
          </h2>
          <div
            v-if="course.title"
            class="mdc-typography--body1 pm-card--course__title"
          >
            {{ course.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MDCRipple} from '@material/ripple';
import MdcProgress from './MdcProgress.vue';

export default {
  name: 'CourseCard',
  components: {
    MdcProgress,
  },
  props: {
    course: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return ({
      mdc: null,
    });
  },
  computed: {
    calculatedProgress() {
      return (1 / 100) * (100 / this.course.lessons_count * this.course.progress);
    },
    active() {
      return (this.course.progress || 0) > 0;
    },
    coverStyles() {
      return {
        'background-image': `url(${this.course.cover})`,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.mdc = new MDCRipple(this.$refs.primary);
    });
  },
  destroyed() {
    if (this.mdc) {
      this.mdc = this.mdc.destroy();
    }
  },
  methods: {
    onClick() {
      if (this.course && this.course.is_available) {
        this.$router.push({name: 'courses.show', params: {id: this.course.id}});
      } else {
        this.$store.commit('showPremiumModal');
      }
    },
  },
};
</script>
