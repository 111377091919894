<template>
  <div class="mdc-card pm-card--lesson pm-card--lesson--fake">
    <div
      :class="{'mdc-card__media': true, 'mdc-card__media--square': true}"
      :style="{'--rotation': 0 + 'deg'}"
    >
      <div class="mdc-theme--on-primary mdc-card__media-content pm-card--lesson__index" />
    </div>
    <div class="pm-card--lesson__inner">
      <h3 class="pm-card--lesson__name">
        <fake :length="13" />
      </h3>
      <div class="pm-card--lesson__actions">
        <div class="pm-card--lesson__levels mdc-typography--button mdc-theme--text-hint-on-light">
          <div :class="{'pm-card--lesson__level': true}">
            <span class="pm-card--lesson__level__name"><fake :length="3" /></span>
            <span class="pm-card--lesson__level__indicator" />
          </div>
          <div :class="{'pm-card--lesson__level': true}">
            <span class="pm-card--lesson__level__name"><fake :length="3" /></span>
            <span class="pm-card--lesson__level__indicator" />
          </div>
          <div :class="{'pm-card--lesson__level': true}">
            <span class="pm-card--lesson__level__name"><fake :length="3" /></span>
            <span class="pm-card--lesson__level__indicator" />
          </div>
        </div>
        <span
          class="pm-card--lesson__start pm-icon-button mdc-icon-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Fake from './Fake.vue';

export default {
  name: 'LessonCardFake',
  components: {
    Fake,
  },
};
</script>
