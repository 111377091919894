var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      ref: "mdcHeader",
      staticClass: "pm-top-app-bar mdc-top-app-bar mdc-top-app-bar--fixed",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "mdc-layout-grid pm-top-app-bar__layout-grid mdc-top-app-bar__row",
        },
        [
          _c(
            "section",
            {
              staticClass:
                "mdc-top-app-bar__section mdc-top-app-bar__section--align-start",
            },
            [
              _c("router-link", { attrs: { to: { name: "landing" } } }, [
                _c("img", {
                  staticClass: "pm-top-app-bar__logo",
                  attrs: {
                    src: require("../../assets/logo.png"),
                    alt: "Lingly logo",
                    title: "Lingly logo",
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              staticClass:
                "pm-only-mobile pm-top-app-bar__section--align-end mdc-top-app-bar__section mdc-top-app-bar__section--align-end",
              attrs: { role: "toolbar" },
            },
            [
              _vm.$store.state.auth.auth
                ? [
                    _c(
                      "mdc-menu",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button",
                              fn: function () {
                                return [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "material-icons mdc-icon-button mdc-top-app-bar__action-item",
                                    },
                                    [
                                      _vm._v(
                                        "\n              menu\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3997026028
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "mdc-list-item",
                            attrs: {
                              tag: "li",
                              role: "menuitem",
                              to: { name: "index" },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v("Přehled kurzů"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "mdc-list-item",
                            attrs: {
                              tag: "li",
                              role: "menuitem",
                              to: { name: "contact-form" },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v("Ptejte se!"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.$store.state.auth.auth.premium
                          ? _c(
                              "li",
                              {
                                staticClass: "mdc-list-item",
                                attrs: { role: "menuitem" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$store.commit("showPremiumModal")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "mdc-list-item__text" },
                                  [_vm._v("Získat PREMIUM")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "mdc-list-item",
                            attrs: {
                              tag: "li",
                              role: "menuitem",
                              to: { name: "profile" },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v("Profil"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "mdc-list-item",
                            attrs: {
                              tag: "li",
                              role: "menuitem",
                              to: { name: "logout" },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v("Odhlásit se"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "mdc-menu",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "button",
                            fn: function () {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "material-icons mdc-icon-button mdc-top-app-bar__action-item",
                                  },
                                  [_vm._v("\n              menu\n            ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "mdc-list-item",
                            attrs: {
                              tag: "li",
                              role: "menuitem",
                              to: { name: "login" },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v("Přihlásit se"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "mdc-list-item",
                            attrs: {
                              tag: "li",
                              role: "menuitem",
                              to: { name: "register" },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v("Začít zdarma"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              staticClass:
                "pm-not-mobile pm-top-app-bar__section--align-end mdc-top-app-bar__section mdc-top-app-bar__section--align-end",
              attrs: { role: "toolbar" },
            },
            [
              _vm.$store.state.auth.auth
                ? [
                    _c("mdc-link", { attrs: { to: { name: "index" } } }, [
                      _vm._v("\n          Přehled kurzů\n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "mdc-link",
                      { attrs: { to: { name: "contact-form" } } },
                      [_vm._v("\n          Ptejte se!\n        ")]
                    ),
                    _vm._v(" "),
                    !_vm.$store.state.auth.auth.premium
                      ? _c(
                          "mdc-button",
                          {
                            staticClass: "pm-button--gradient",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.$store.commit("showPremiumModal")
                              },
                            },
                          },
                          [_vm._v("\n          Získat PREMIUM\n        ")]
                        )
                      : _c(
                          "mdc-button",
                          {
                            staticClass: "pm-button--orange",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.$router.push({ name: "profile" })
                              },
                            },
                          },
                          [_vm._v("\n          LINGLY PREMIUM\n        ")]
                        ),
                    _vm._v(" "),
                    _c(
                      "mdc-menu",
                      {
                        staticClass: "pm-top-app-bar__profile",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button",
                              fn: function () {
                                return [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "pm-icon-button mdc-icon-button mdc-top-app-bar__action-item",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mdc-icon-button__icon",
                                        attrs: {
                                          src: require("../../assets/profile.svg"),
                                          alt: "Profil",
                                          title: "Profil",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2300421531
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "mdc-list-item",
                            attrs: {
                              tag: "li",
                              role: "menuitem",
                              to: { name: "profile" },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v("Profil"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "mdc-list-item",
                            attrs: {
                              tag: "li",
                              role: "menuitem",
                              to: { name: "logout" },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v("Odhlásit se"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "mdc-link",
                      { attrs: { dark: "", to: { name: "login" } } },
                      [_vm._v("\n          Přihlásit se\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "mdc-link",
                      { attrs: { dark: "", to: { name: "register" } } },
                      [_vm._v("\n          Začít zdarma\n        ")]
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }