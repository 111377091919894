<template>
  <div
    ref="mdc"
    role="progressbar"
    class="mdc-linear-progress"
    aria-label="Progress bar"
    aria-valuemin="0"
    aria-valuemax="1"
    aria-valuenow="0"
  >
    <div class="mdc-linear-progress__buffering-dots" />
    <div class="mdc-linear-progress__buffer" />
    <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
      <span
        ref="iteration"
        class="mdc-linear-progress__bar-inner"
      />
    </div>
    <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
      <span class="mdc-linear-progress__bar-inner" />
    </div>
  </div>
</template>

<script>
import {MDCLinearProgress} from '@material/linear-progress';

export default {
  name: 'MdcProgress',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    buffer: {
      type: Number,
      default: 0,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
    buffered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
      mdc: null,
      buffEnabled: this.buffered,
      indeterminateStop: false,
    });
  },
  watch: {
    progress(value) {
      if (this.mdc) {
        this.mdc.progress = value;
      }
    },
    buffer(value) {
      if (this.mdc && this.buffEnabled) {
        this.mdc.buffer = value;
      }
    },
    indeterminate(value) {
      if (this.mdc) {
        if (true === value) {
          this.mdc.determinate = false;
          this.indeterminateStop = false;
        } else {
          this.indeterminateStop = true;
        }
      }
    },
    reversed(value) {
      if (this.mdc) {
        this.mdc.reverse = value;
      }
    },
    buffered(value) {
      this.buffEnabled = value;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.mdc = new MDCLinearProgress(this.$refs.mdc);
      this.mdc.progress = this.progress;

      if (this.buffEnabled) {
        this.mdc.buffer = this.buffer;
      }

      if (this.reversed) {
        this.mdc.reverse = this.reversed;
      }

      if (this.indeterminate) {
        this.mdc.determinate = !this.indeterminate;
      }

      this.$refs.iteration.addEventListener('animationiteration', this.onIteration.bind(this));
    });
  },
  beforeDestroy() {
    this.$refs.iteration.removeEventListener('animationiteration', this.onIteration.bind(this));
  },
  destroyed() {
    if (this.mdc) {
      this.mdc = this.mdc.destroy();
    }
  },
  methods: {
    onIteration() {
      if (this.indeterminateStop) {
        this.mdc.determinate = true;
        this.indeterminateStop = false;
      }
    },
  },
};
</script>
