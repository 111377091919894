var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pm-section-grid" }, [
    _c(
      "h2",
      { staticClass: "mdc-typography--headline3" },
      [_c("fake", { attrs: { length: 13 } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pm-lessons-grid" },
      _vm._l(12, function (i) {
        return _c("lesson-card-fake", { key: i })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }