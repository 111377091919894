<template>
  <main class="mdc-landing">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <h1
            class="mdc-typography--headline1"
            style=" margin-top: 1em; margin-bottom: 0.5em;"
          >
            <span>Hi! Jsme Lingly, </span>
            <span style="font-weight: 400;"> angličtina online.</span>
          </h1>
          <p
            class="mdc-typography--body1"
            style=" margin-bottom: 1em; font-size: 200%; line-height: 1.5;"
          >
            Pojďte se s námi učit anglicky! Naše interaktivní cvičení si zamilujete. ❤️ A k tomu vás na dálku povedeme za ruku. Nebo za obě.
          </p>
          <div style="margin-bottom: 3em;">
            <router-link :to="{name: 'index', params: {guest: 'register'}}">
              <span
                class="mdc-button mdc-button--raised"
                style="height: auto; padding: 1em 3em; border-radius: 1em;"
              >
                <span
                  class="mdc-button__label mdc-typography--headline6"
                  style=" font-size: var(--mdc-typography-headline6-font-size, 1.45rem); font-weight: 700;"
                >Začít zdarma</span>
              </span>
            </router-link>
          </div>
          <ul
            class="mdc-landing__checkmarks"
          >
            <li class="mdc-landing__checkmark">
              <span class="material-icons">check</span><span class="mdc-landing__checkmark__label">Slovíčka</span>
            </li>
            <li class="mdc-landing__checkmark">
              <span class="material-icons">check</span><span class="mdc-landing__checkmark__label">Gramatika</span>
            </li>
            <li class="mdc-landing__checkmark">
              <span class="material-icons">check</span><span class="mdc-landing__checkmark__label">Fráze</span>
            </li>
            <li class="mdc-landing__checkmark">
              <span class="material-icons">check</span><span class="Landing__checkmark__label">Chytáky</span>
            </li>
          </ul>
        </div>
        <hr
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
          style="margin: 4em 0;"
        >
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
        >
          <h2
            style="margin-bottom: 1.2em; text-align: center;"
            class="mdc-typography--headline2"
          >
            Proč se učit s Lingly?
          </h2>
          <div class="mdc-layout-grid__inner">
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet"
            >
              <div class="pm-card pm-card--course mdc-card">
                <div class="pm-card__content">
                  <h3
                    class="mdc-typography--headline5"
                    style=" margin-bottom: 0.6em; font-weight: 700;"
                  >
                    Tvoříme kvalitní obsah
                  </h3>
                  <p class="mdc-typography--headline6">
                    Každou naši lekci namlouvá rodilý mluvčí a pečlivě kontrolují zkušení lektoři. Samozřejmostí jsou české překlady (bez chyb!) a doprovodné fotografie.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet"
            >
              <div class="pm-card pm-card--course mdc-card">
                <div class="pm-card__content">
                  <h3
                    class="mdc-typography--headline5"
                    style=" margin-bottom: 0.6em; font-weight: 700;"
                  >
                    Učíme praktickou angličtinu
                  </h3>
                  <p class="mdc-typography--headline6">
                    Vše, co se u nás naučíte, můžete ihned začít používat – na dovolené, v práci, ve škole nebo na rande. 😉
                  </p>
                </div>
              </div>
            </div>
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet"
            >
              <div class="pm-card pm-card--course mdc-card">
                <div class="pm-card__content">
                  <h3
                    class="mdc-typography--headline5"
                    style=" margin-bottom: 0.6em; font-weight: 700;"
                  >
                    Jsme vždy po ruce
                  </h3>
                  <p class="mdc-typography--headline6">
                    Lingly se nemusí instalovat a bude vám fungovat na počítači, mobilu i tabletu. Díky tomu se můžete učit doma i na cestách.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet"
            >
              <div class="pm-card pm-card--course mdc-card">
                <div class="pm-card__content">
                  <h3
                    class="mdc-typography--headline5"
                    style=" margin-bottom: 0.6em; font-weight: 700;"
                  >
                    Poradíme vám ❤️
                  </h3>
                  <p class="mdc-typography--headline6">
                    Ať už plavete v gramatice nebo si nejste jisti slovíčkem, rádi vám poradíme. S námi na to nikdy nebudete sami.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
          style="margin: 4em 0;"
        >
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
        >
          <div
            class="mdc-layout-grid__inner"
            style="grid-gap: 4em;"
          >
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
              <div
                class="mdc-typography--headline1"
                style="line-height: 1.5;"
              >
                Procvičování slovíček, poslechu i gramatiky.
              </div>
            </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
              <div
                class="mdc-typography--headline5"
                style=" margin-top: 0.5em; line-height: 1.8;"
              >
                V Lingly najdete <strong>tisíce otázek</strong> zaměřených na rozšiřování slovní zásoby, trénování poslechu nebo třeba pilování gramatiky. Je na vás, co si zrovna vyberete.
              </div>
            </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
              <div class="pm-card pm-card--course mdc-card">
                <div class="pm-card__content">
                  <img
                    src="../../assets/landing_sample_1.png"
                    alt="Ukázka aplikace"
                    style="max-width: 100%;"
                  >
                </div>
              </div>
            </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
              <div class="pm-card pm-card--course mdc-card">
                <div class="pm-card__content">
                  <img
                    src="../../assets/landing_sample_2.png"
                    alt="Ukázka aplikace"
                    style="max-width: 100%;"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
          style="margin: 4em 0;"
        >
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
        >
          <h2
            style="margin-bottom: 0.5em; text-align: center;"
            class="mdc-typography--headline2"
          >
            Chcete se s námi učit?
          </h2>
          <p
            class="mdc-typography--body1"
            style=" margin-bottom: 1em; font-size: 200%; line-height: 1.5; text-align: center;"
          >
            Pokud přemýšlíte, jak nejlépe začít, určitě nám napište!
          </p>
          <form
            method="POST"
            @submit.prevent="handleSubmit"
          >
            <div class="mdc-layout-grid__inner">
              <div class="mdc-layout-grid__cell--span-12">
                <div class="mdc-layout-grid__inner">
                  <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet">
                    <label
                      ref="mdcName"
                      style="width: 100%;"
                      class="mdc-text-field mdc-text-field--outlined"
                    >
                      <input
                        v-model="name"
                        required
                        type="text"
                        class="mdc-text-field__input"
                        :disabled="submited || loading"
                      >
                      <span class="mdc-notched-outline">
                        <span class="mdc-notched-outline__leading" />
                        <span class="mdc-notched-outline__notch">
                          <span class="mdc-floating-label">Jméno</span>
                        </span>
                        <span class="mdc-notched-outline__trailing" />
                      </span>
                    </label>
                  </div>
                  <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet">
                    <label
                      ref="mdcEmail"
                      style="width: 100%;"
                      class="mdc-text-field mdc-text-field--outlined"
                    >
                      <input
                        v-model="email"
                        required
                        type="email"
                        class="mdc-text-field__input"
                        :disabled="submited || loading"
                      >
                      <span class="mdc-notched-outline">
                        <span class="mdc-notched-outline__leading" />
                        <span class="mdc-notched-outline__notch">
                          <span class="mdc-floating-label">E-mail</span>
                        </span>
                        <span class="mdc-notched-outline__trailing" />
                      </span>
                    </label>
                  </div>
                  <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                    <label
                      ref="mdcContent"
                      class="mdc-text-field mdc-text-field--textarea"
                      style="width: 100%;"
                    >
                      <textarea
                        v-model="content"
                        class="mdc-text-field__input"
                        rows="8"
                        cols="40"
                        :disabled="submited || loading"
                        required
                      />
                      <span class="mdc-notched-outline">
                        <span class="mdc-notched-outline__leading" />
                        <span class="mdc-notched-outline__notch">
                          <label
                            class="mdc-floating-label"
                          >Na co byste se chtěli zeptat?</label>
                        </span>
                        <span class="mdc-notched-outline__trailing" />
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                style="text-align: center;"
                class="mdc-layout-grid__cell--span-12"
              >
                <button
                  type="submit"
                  class="mdc-button mdc-button--raised"
                  :disabled="submited || loading"
                  style="height: 2em; font-size: 2rem; line-height: 1;"
                >
                  <span
                    class="mdc-button__label"
                    style=" width: auto; height: auto; font-size: 1em; line-height: 1;"
                  >
                    Zeptat se
                  </span>
                  <i
                    class="material-icons mdc-button__icon"
                    aria-hidden="true"
                    style=" width: auto; height: auto; font-size: 1em; line-height: 1;"
                  >arrow_right_alt</i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <footer
      style="margin-top: 2em;"
      class="mdc-landing__footer mdc-typography--headline6 mdc-elevation--z4"
    >
      <div><small>© 2020 Lingly s.r.o.</small></div>
      <div class="mdc-landing__footer__end">
        <a href="tel:+420607430332">+420 607 430 332</a><a href="mailto:hello@lingly.cz">hello@lingly.cz</a><a :href="vop">Všeobecné obchodní podmínky</a>
      </div>
    </footer>
  </main>
</template>

<script>
import {MDCTextField} from '@material/textfield';

import vop from '../../assets/2020-08-18-Lingly-obchodni-podminky.pdf';

export default {
  name: 'Landing',
  data() {
    return {
      loading: false,
      content: '',
      email: '',
      name: '',
      submited: false,
      mdcContent: null,
      mdcEmail: null,
      mdcName: null,
    };
  },
  computed: {
    vop() {
      return vop;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.mdcContent = new MDCTextField(this.$refs.mdcContent);
      this.mdcEmail = new MDCTextField(this.$refs.mdcEmail);
      this.mdcName = new MDCTextField(this.$refs.mdcName);
    });
  },
  destroyed() {
    if (this.mdcContent) {
      this.mdcContent = this.mdcContent.destroy();
    }
    if (this.mdcEmail) {
      this.mdcEmail = this.mdcEmail.destroy();
    }
    if (this.mdcName) {
      this.mdcName = this.mdcName.destroy();
    }
  },
  methods: {
    handleSubmit() {
      if (this.loading || this.submited) {
        return;
      }

      this.loading = true;

      const data = {
        content: this.content,
        email: this.email,
        name: this.name,
        source: 'landing',
      };

      this.$store.dispatch('request', {route: 'contact-form.store', data: data}).then(() => {
        this.submited = true;

        this.$store.dispatch('info', 'Děkujeme! E-mail s odpovědí odešleme co nejdříve.');
      }, () => {}).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
