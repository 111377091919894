<template>
  <div class="pm-auth-grid">
    <div class="pm-card pm-card--auth mdc-card">
      <mdc-progress
        :indeterminate="$store.state.auth.authProgress"
        :progress="0"
        class="pm-auth-grid__progress"
      />
      <div class="pm-card__content">
        <transition
          name="pm-router"
          mode="out-in"
          appear
        >
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import MdcProgress from '../components/MdcProgress.vue';

export default {
  name: 'Auth',
  components: {
    MdcProgress,
  },
};
</script>
