<template>
  <div
    ref="ref"
    :class="classes"
  >
    <div class="mdc-snackbar__surface">
      <div
        class="mdc-snackbar__label"
        role="status"
        aria-live="polite"
      >
        {{ notification.text }}
      </div>
      <div class="mdc-snackbar__actions">
        <button
          type="button"
          class="mdc-icon-button mdc-snackbar__dismiss material-icons"
        >
          close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {MDCSnackbar} from '@material/snackbar';

export default {
  name: 'Notification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdc: null,
      timeout: null,
    };
  },
  computed: {
    classes() {
      return {
        'mdc-snackbar': true,
        'pm-snackbar--error': 'error' === this.notification.type,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.timeout = setTimeout(() => {
        this.mdc = new MDCSnackbar(this.$refs.ref);

        this.mdc.timeoutMs = this.notification.time;

        this.mdc.listen('MDCSnackbar:closed', (event) => {
          this.$store.commit('removeNotification', this.notification);
        });

        this.mdc.open();
      }, 100);
    });
  },
  beforeDestroy() {
    if (this.mdc) {
      this.mdc = this.mdc.close();
    }
  },
  destroyed() {
    if (this.mdc) {
      this.mdc = this.mdc.destroy();
    }
  },
};
</script>
