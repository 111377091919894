<template>
  <div
    id="app"
  >
    <mdc-header :class="{'pm-top-app-bar--hidden': $store.state.topAppBarHidden}" />

    <div
      style="height: 100%;"
      :class="{'mdc-top-app-bar--fixed-adjust': true, 'pm-top-app-bar--hidden--adjust': $store.state.topAppBarHidden}"
    >
      <transition
        name="pm-router"
        mode="out-in"
        appear
      >
        <router-view
          :key="true === $route.meta.keyed ? $route.fullPath : 'router-view'"
        />
      </transition>
    </div>

    <notification
      v-if="notification"
      :key="notification.id"
      :notification="notification"
    />

    <premium-modal
      v-if="$store.state.modals.showPremiumModal"
      :key="$store.state.modals.key"
    />
  </div>
</template>

<script>
import Notification from '../components/Notification.vue';
import MdcHeader from '../components/MdcHeader.vue';
import PremiumModal from '../components/PremiumModal.vue';

export default {
  name: 'App',
  components: {
    Notification,
    MdcHeader,
    PremiumModal,
  },
  computed: {
    notification() {
      return this.$store.state.notifications.notifications[0];
    },
  },
};
</script>
