<template>
  <form
    ref="form"
    class="pm-form mdc-layout-grid"
    @submit.prevent="onSubmit"
  >
    <div class="pm-form__title mdc-typography--headline6">
      {{ $route.params.authed === true ? 'Před pokračováním do aplikace musíte mít ověřený účet!' : 'Opětovné odeslání ověřovacího odkazu.' }}
    </div>
    <div
      v-if="$route.params.authed === true"
      class="pm-form__title mdc-typography--body1"
    >
      Níže můžete požádat o opětovné zaslání odkazu.
    </div>
    <div class="pm-form__form mdc-layout-grid__inner">
      <mdc-form-text
        v-model="email"
        type="email"
        name="email"
        required
        form="resend"
        label="E-mail"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
      />
    </div>
    <div class="pm-form__actions pm-form__actions--right">
      <mdc-button
        type="submit"
        raised
        :disabled="loading"
        :pm="false"
      >
        Odeslat
      </mdc-button>
    </div>
  </form>
</template>

<script>
import MdcFormText from '../components/MdcFormText.vue';
import MdcButton from '../components/MdcButton.vue';

export default {
  name: 'Resend',
  components: {
    MdcFormText,
    MdcButton,
  },
  data() {
    return ({
      email: '',
      loading: false,
    });
  },
  methods: {
    onSubmit() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.$store.dispatch('authLoader', true);
      this.$store
          .dispatch('request', {route: 'resend', data: this.$refs.form})
          .then(() => {
            this.$router.push({name: 'verify', params: {email: this.email}}).then(() => {
              this.$store.dispatch('info', 'E-mail odeslán!');
            }).finally(() => {
              this.$store.dispatch('authLoader', false);
              this.loading = false;
            });
          })
          .catch((error) => {
            if (error.status.status === 409) {
              this.$router.push({name: 'login'}).then(() => {
                this.$store.dispatch('error', 'E-mail již je ověřený!');
              }).finally(() => {
                this.$store.dispatch('authLoader', false);
                this.loading = false;
              });
            } else {
              this.$store.dispatch('authLoader', false);
              this.loading = false;
            }
          });
    },
  },
};
</script>
