<template>
  <router-link
    :to="to"
    exact-active-class="pm-router-link-exact-active"
    :class="{'pm-router-link': true, 'pm-router-link--dark': dark ? true : false}"
  >
    <mdc-button
      :instantiate="instantiate"
      :pre-icon="preIcon"
      :post-icon="postIcon"
      :pm="pm"
      :raised="raised"
      :unelevated="unelevated"
      :outlined="outlined"
    >
      <slot />
    </mdc-button>
  </router-link>
</template>

<script>
import MdcButton from './MdcButton.vue';

export default {
  name: 'MdcLink',
  components: {
    MdcButton,
  },
  props: {
    preIcon: {
      type: String,
      required: false,
      default: null,
    },
    postIcon: {
      type: String,
      required: false,
      default: null,
    },
    instantiate: {
      type: Boolean,
      default: true,
    },
    to: {
      type: Object,
      required: true,
    },
    pm: {
      type: Boolean,
      default: true,
    },
    raised: {
      type: Boolean,
      default: false,
    },
    unelevated: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
