var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "pm-form mdc-layout-grid",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "pm-form__title mdc-typography--headline6" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$route.params.authed === true
                ? "Před pokračováním do aplikace musíte mít ověřený účet!"
                : "Opětovné odeslání ověřovacího odkazu."
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.$route.params.authed === true
        ? _c("div", { staticClass: "pm-form__title mdc-typography--body1" }, [
            _vm._v("\n    Níže můžete požádat o opětovné zaslání odkazu.\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pm-form__form mdc-layout-grid__inner" },
        [
          _c("mdc-form-text", {
            staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
            attrs: {
              type: "email",
              name: "email",
              required: "",
              form: "resend",
              label: "E-mail",
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pm-form__actions pm-form__actions--right" },
        [
          _c(
            "mdc-button",
            {
              attrs: {
                type: "submit",
                raised: "",
                disabled: _vm.loading,
                pm: false,
              },
            },
            [_vm._v("\n      Odeslat\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }