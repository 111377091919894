<template>
  <button
    ref="button"
    :class="classes"
  >
    <div class="mdc-button__ripple" />
    <span
      v-if="preIcon"
      class="material-icons mdc-button__icon"
      aria-hidden="true"
    >{{ preIcon }}</span>
    <span class="pm-button__label mdc-button__label">
      <slot />
    </span>
    <span
      v-if="postIcon"
      class="material-icons mdc-button__icon"
      aria-hidden="true"
    >{{ postIcon }}</span>
  </button>
</template>

<script>
import {MDCRipple} from '@material/ripple';

export default {
  name: 'MdcButton',
  props: {
    preIcon: {
      type: String,
      required: false,
      default: null,
    },
    postIcon: {
      type: String,
      required: false,
      default: null,
    },
    instantiate: {
      type: Boolean,
      default: true,
    },
    pm: {
      type: Boolean,
      default: true,
    },
    raised: {
      type: Boolean,
      default: false,
    },
    unelevated: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
      mdc: null,
    });
  },
  computed: {
    classes() {
      return ({
        'pm-button': this.pm,
        'mdc-button': true,
        'mdc-button--raised': this.raised,
        'mdc-button--unelevated': this.unelevated,
        'mdc-button--outlined': this.outlined,
      });
    },
  },
  mounted() {
    if (this.instantiate) {
      this.$nextTick(() => {
        this.mdc = new MDCRipple(this.$refs.button);
      });
    }
  },
  destroyed() {
    if (this.mdc) {
      this.mdc = this.mdc.destroy();
    }
  },
};
</script>
