var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", { ref: "button", class: _vm.classes }, [
    _c("div", { staticClass: "mdc-button__ripple" }),
    _vm._v(" "),
    _vm.preIcon
      ? _c(
          "span",
          {
            staticClass: "material-icons mdc-button__icon",
            attrs: { "aria-hidden": "true" },
          },
          [_vm._v(_vm._s(_vm.preIcon))]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "pm-button__label mdc-button__label" },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _vm.postIcon
      ? _c(
          "span",
          {
            staticClass: "material-icons mdc-button__icon",
            attrs: { "aria-hidden": "true" },
          },
          [_vm._v(_vm._s(_vm.postIcon))]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }