<template>
  <div class="pm-form mdc-layout-grid">
    <div
      v-if="$route.params.email"
      class="pm-form__title mdc-typography--headline6"
    >
      Ověřovací e-mail odeslán na {{ this.$route.params.email }}
    </div>
    <div
      v-else
      class="pm-form__title mdc-typography--headline6"
    >
      Ověřuji e-mail
    </div>
  </div>
</template>

<script>
export default {
  name: 'Verify',
  created() {
    if (!this.$route.params.email) {
      this.$store.dispatch('authLoader', true);
    }

    if (!(this.$route.query.url) && !(this.$route.params.email)) {
      this.$router.push({name: 'resend'}).finally(() => {
        this.$store.dispatch('authLoader', false);
      });
    } else if (this.$route.query.url && !this.$route.params.email) {
      this.$store.dispatch('request', {route: 'verify', url: this.$route.query.url})
          .then((response) => {
            this.$store.commit('setAuth', response.json);
            this.$store.commit('refresh');

            this.$router.push({name: 'index'}).then(() => {
              this.$store.dispatch('info', 'E-mail ověřen!');
            }).finally(() => {
              this.$store.dispatch('authLoader', false);
            });
          }).catch((error) => {
            if (error.status.status === 403) {
              this.$router.push({name: 'resend'}).then(() => {
                this.$store.dispatch('error', 'Neplatný odkaz!');
              }).finally(() => {
                this.$store.dispatch('authLoader', false);
              });
            } else if (error.status.status === 409) {
              this.$router.push({name: 'login'}).then(() => {
                this.$store.dispatch('error', 'E-mail již je ověřený!');
              }).finally(() => {
                this.$store.dispatch('authLoader', false);
              });
            } else {
              this.$store.dispatch('authLoader', false);
            }
          });
    } else {
      this.$store.dispatch('authLoader', false);
    }
  },
};
</script>
