var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "pm-form mdc-layout-grid",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "pm-form__title mdc-typography--headline6" }, [
        _vm._v("\n    Obnovte si heslo zasláním odkazu na e-mail.\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pm-form__form mdc-layout-grid__inner" },
        [
          _c("mdc-form-text", {
            staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
            attrs: {
              type: "email",
              name: "email",
              required: "",
              form: "forgot",
              label: "E-mailová adresa",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pm-form__actions pm-form__actions--right" },
        [
          _c(
            "mdc-button",
            {
              attrs: {
                type: "submit",
                disabled: _vm.loading,
                raised: "",
                pm: false,
              },
            },
            [_vm._v("\n      Odeslat\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pm-form__actions" },
        [
          _c("mdc-link", { attrs: { to: { name: "register" }, pm: false } }, [
            _vm._v("\n      Registrace\n    "),
          ]),
          _vm._v(" "),
          _c("mdc-link", { attrs: { to: { name: "login" }, pm: false } }, [
            _vm._v("\n      Přihlásit se\n    "),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }