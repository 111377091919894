import Vue from 'vue';
import VueRouter from 'vue-router';

import Fallback from '../views/Fallback.vue';
import Auth from '../views/Auth.vue';
import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import Register from '../views/Register.vue';
import Forgot from '../views/Forgot.vue';
import Change from '../views/Change.vue';
import Verify from '../views/Verify.vue';
import Resend from '../views/Resend.vue';
import CourseIndex from '../views/CourseIndex.vue';
import CourseShow from '../views/CourseShow.vue';
import LessonShow from '../views/LessonShow.vue';
import Profile from '../views/Profile.vue';
import ContactForm from '../views/ContactForm.vue';
import Landing from '../views/Landing.vue';

import store from '../store';

import aliases from './aliases';

Vue.use(VueRouter);

const routes = [
  ...aliases,
  {
    path: '/ucet',
    component: Auth,
    children: [
      {
        name: 'login',
        path: 'prihlaseni',
        component: Login,
        meta: {
          guestOnly: true,
        },
      },
      {
        name: 'register',
        path: 'registrace',
        component: Register,
        meta: {
          guestOnly: true,
        },
      },
      {
        name: 'forgot',
        path: 'obnova-hesla',
        component: Forgot,
        meta: {
          guestOnly: true,
        },
      },
      {
        name: 'change',
        path: 'zmena-hesla',
        component: Change,
        meta: {
          guestOnly: true,
        },
      },
      {
        name: 'verify',
        path: 'overeni',
        component: Verify,
        meta: {
          guest: true,
          verified: false,
        },
      },
      {
        name: 'resend',
        path: 'overit-znovu',
        component: Resend,
        meta: {
          guest: true,
          verified: false,
        },
      },
      {
        name: 'logout',
        path: 'odhlasit',
        component: Logout,
        meta: {
          verified: null,
        },
      },
      {
        name: 'profile',
        path: 'profil',
        component: Profile,
      },
      {
        path: '**',
        redirect: {name: 'fallback'},
      },
    ],
  },
  {
    name: 'contact-form',
    path: '/ptejte-se',
    component: ContactForm,
    meta: {
      guest: true,
    },
  },
  {
    name: 'landing',
    path: '/',
    component: Landing,
    meta: {
      guest: true,
      guestOnly: true,
    },
  },
  {
    name: 'index',
    path: '/kurzy',
    component: CourseIndex,
  },
  {
    name: 'about',
    path: '/o-nas',
    component: Fallback,
    meta: {
      guest: true,
    },
  },
  {
    name: 'courses.show',
    path: '/kurzy/:id',
    component: CourseShow,
    meta: {
      keyed: true,
    },
  },
  {
    name: 'lessons.show',
    path: '/kurzy/:courseId/lekce/:lessonId',
    component: LessonShow,
    meta: {
      keyed: true,
      hideTopAppBar: true,
    },
  },
  {
    name: 'fallback',
    path: '**',
    component: Fallback,
    meta: {
      guest: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.meta.hideTopAppBar) {
    if (!to.meta.hideTopAppBar) {
      store.commit('showTopAppBar');
    }
  } else if (to.meta.hideTopAppBar) {
    store.commit('hideTopAppBar');
  }

  if (store.state.auth.auth) {
    // authed
    if (to.meta.guestOnly) {
      return next({name: 'index'});
    }

    if (to.meta.verified === null) {
      return next();
    }

    if (to.meta.verified === false) {
      if (store.state.auth.auth.verified === true) {
        return next({name: 'index'});
      } else {
        return next();
      }
    }

    if (store.state.auth.auth.verified === true) {
      return next();
    } else {
      return next({name: 'resend', params: {authed: true}});
    }
  } else {
    // guest
    if (to.meta.guestOnly || to.meta.guest) {
      return next();
    } else {
      store.commit('setIntended', to.fullPath);

      if (to.params && to.params.guest === 'register') {
        return next({name: 'register'});
      }

      return next({name: 'login'});
    }
  }
});

export default router;
