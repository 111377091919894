var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.course && _vm.loading
        ? _c(
            "div",
            { staticClass: "mdc-layout-grid pm-course-grid" },
            [
              _c(
                "h1",
                { staticClass: "mdc-typography--headline2" },
                [_c("fake", { attrs: { length: 12 } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pm-course-grid__progress pm-card--course__progress",
                },
                [
                  _c("mdc-progress", {
                    staticClass: "pm-card--course__progress__line",
                    attrs: { indeterminate: "", progress: 0 },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pm-card--course__progress__count mdc-typography--button",
                    },
                    [
                      _c(
                        "span",
                        {
                          class: [
                            "pm-card--course__progress__from",
                            "mdc-theme--text-hint-on-light",
                          ],
                        },
                        [_c("fake", { attrs: { length: 3 } })],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(2, function (i) {
                return _c("section-lessons-fake", { key: i })
              }),
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "mdc-layout-grid pm-course-grid" },
            [
              _c("h1", { staticClass: "mdc-typography--headline2" }, [
                _vm._v("\n      " + _vm._s(_vm.course.name) + "\n    "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pm-course-grid__progress pm-card--course__progress",
                },
                [
                  _c("mdc-progress", {
                    staticClass: "pm-card--course__progress__line",
                    attrs: { progress: _vm.calculatedProgress },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pm-card--course__progress__count mdc-typography--button",
                    },
                    [
                      _c(
                        "span",
                        {
                          class: [
                            "pm-card--course__progress__from",
                            _vm.active
                              ? "mdc-theme--text-primary-on-light"
                              : "mdc-theme--text-hint-on-light",
                          ],
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.course.progress) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "mdc-theme--text-hint-on-light" },
                        [
                          _vm._v(
                            "\n          / " +
                              _vm._s(_vm.course.lessons_count) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.course.sections, function (section, i) {
                return _c("section-lessons", {
                  key: section.id,
                  attrs: { section: section, index: _vm.indexMethod(i) },
                })
              }),
            ],
            2
          ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          class: { "mdc-fab": true, "pm-fab--back": true },
          attrs: { "aria-label": "Zpět", to: { name: "index" }, tag: "button" },
        },
        [
          _c("div", { staticClass: "mdc-fab__ripple" }),
          _vm._v(" "),
          _c("span", { staticClass: "mdc-fab__icon material-icons" }, [
            _vm._v("keyboard_arrow_left"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }