var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "mdc-landing" }, [
    _c("div", { staticClass: "mdc-layout-grid" }, [
      _c("div", { staticClass: "mdc-layout-grid__inner" }, [
        _c(
          "div",
          {
            staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mdc-typography--body1",
                staticStyle: {
                  "margin-bottom": "1em",
                  "font-size": "200%",
                  "line-height": "1.5",
                },
              },
              [
                _vm._v(
                  "\n          Pojďte se s námi učit anglicky! Naše interaktivní cvičení si zamilujete. ❤️ A k tomu vás na dálku povedeme za ruku. Nebo za obě.\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-bottom": "3em" } },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "index", params: { guest: "register" } },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "mdc-button mdc-button--raised",
                        staticStyle: {
                          height: "auto",
                          padding: "1em 3em",
                          "border-radius": "1em",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "mdc-button__label mdc-typography--headline6",
                            staticStyle: {
                              "font-size":
                                "var(--mdc-typography-headline6-font-size, 1.45rem)",
                              "font-weight": "700",
                            },
                          },
                          [_vm._v("Začít zdarma")]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(1),
          ]
        ),
        _vm._v(" "),
        _c("hr", {
          staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
          staticStyle: { margin: "4em 0" },
        }),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("hr", {
          staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
          staticStyle: { margin: "4em 0" },
        }),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("hr", {
          staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
          staticStyle: { margin: "4em 0" },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
          },
          [
            _c(
              "h2",
              {
                staticClass: "mdc-typography--headline2",
                staticStyle: {
                  "margin-bottom": "0.5em",
                  "text-align": "center",
                },
              },
              [_vm._v("\n          Chcete se s námi učit?\n        ")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mdc-typography--body1",
                staticStyle: {
                  "margin-bottom": "1em",
                  "font-size": "200%",
                  "line-height": "1.5",
                  "text-align": "center",
                },
              },
              [
                _vm._v(
                  "\n          Pokud přemýšlíte, jak nejlépe začít, určitě nám napište!\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "form",
              {
                attrs: { method: "POST" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "mdc-layout-grid__inner" }, [
                  _c("div", { staticClass: "mdc-layout-grid__cell--span-12" }, [
                    _c("div", { staticClass: "mdc-layout-grid__inner" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet",
                        },
                        [
                          _c(
                            "label",
                            {
                              ref: "mdcName",
                              staticClass:
                                "mdc-text-field mdc-text-field--outlined",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.name,
                                    expression: "name",
                                  },
                                ],
                                staticClass: "mdc-text-field__input",
                                attrs: {
                                  required: "",
                                  type: "text",
                                  disabled: _vm.submited || _vm.loading,
                                },
                                domProps: { value: _vm.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.name = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm._m(4),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet",
                        },
                        [
                          _c(
                            "label",
                            {
                              ref: "mdcEmail",
                              staticClass:
                                "mdc-text-field mdc-text-field--outlined",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.email,
                                    expression: "email",
                                  },
                                ],
                                staticClass: "mdc-text-field__input",
                                attrs: {
                                  required: "",
                                  type: "email",
                                  disabled: _vm.submited || _vm.loading,
                                },
                                domProps: { value: _vm.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.email = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm._m(5),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mdc-layout-grid__cell mdc-layout-grid__cell--span-12",
                        },
                        [
                          _c(
                            "label",
                            {
                              ref: "mdcContent",
                              staticClass:
                                "mdc-text-field mdc-text-field--textarea",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.content,
                                    expression: "content",
                                  },
                                ],
                                staticClass: "mdc-text-field__input",
                                attrs: {
                                  rows: "8",
                                  cols: "40",
                                  disabled: _vm.submited || _vm.loading,
                                  required: "",
                                },
                                domProps: { value: _vm.content },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.content = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm._m(6),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mdc-layout-grid__cell--span-12",
                      staticStyle: { "text-align": "center" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "mdc-button mdc-button--raised",
                          staticStyle: {
                            height: "2em",
                            "font-size": "2rem",
                            "line-height": "1",
                          },
                          attrs: {
                            type: "submit",
                            disabled: _vm.submited || _vm.loading,
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "mdc-button__label",
                              staticStyle: {
                                width: "auto",
                                height: "auto",
                                "font-size": "1em",
                                "line-height": "1",
                              },
                            },
                            [
                              _vm._v(
                                "\n                  Zeptat se\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons mdc-button__icon",
                              staticStyle: {
                                width: "auto",
                                height: "auto",
                                "font-size": "1em",
                                "line-height": "1",
                              },
                              attrs: { "aria-hidden": "true" },
                            },
                            [_vm._v("arrow_right_alt")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "footer",
      {
        staticClass:
          "mdc-landing__footer mdc-typography--headline6 mdc-elevation--z4",
        staticStyle: { "margin-top": "2em" },
      },
      [
        _vm._m(7),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-landing__footer__end" }, [
          _c("a", { attrs: { href: "tel:+420607430332" } }, [
            _vm._v("+420 607 430 332"),
          ]),
          _c("a", { attrs: { href: "mailto:hello@lingly.cz" } }, [
            _vm._v("hello@lingly.cz"),
          ]),
          _c("a", { attrs: { href: _vm.vop } }, [
            _vm._v("Všeobecné obchodní podmínky"),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h1",
      {
        staticClass: "mdc-typography--headline1",
        staticStyle: { "margin-top": "1em", "margin-bottom": "0.5em" },
      },
      [
        _c("span", [_vm._v("Hi! Jsme Lingly, ")]),
        _vm._v(" "),
        _c("span", { staticStyle: { "font-weight": "400" } }, [
          _vm._v(" angličtina online."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "mdc-landing__checkmarks" }, [
      _c("li", { staticClass: "mdc-landing__checkmark" }, [
        _c("span", { staticClass: "material-icons" }, [_vm._v("check")]),
        _c("span", { staticClass: "mdc-landing__checkmark__label" }, [
          _vm._v("Slovíčka"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mdc-landing__checkmark" }, [
        _c("span", { staticClass: "material-icons" }, [_vm._v("check")]),
        _c("span", { staticClass: "mdc-landing__checkmark__label" }, [
          _vm._v("Gramatika"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mdc-landing__checkmark" }, [
        _c("span", { staticClass: "material-icons" }, [_vm._v("check")]),
        _c("span", { staticClass: "mdc-landing__checkmark__label" }, [
          _vm._v("Fráze"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mdc-landing__checkmark" }, [
        _c("span", { staticClass: "material-icons" }, [_vm._v("check")]),
        _c("span", { staticClass: "Landing__checkmark__label" }, [
          _vm._v("Chytáky"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12" },
      [
        _c(
          "h2",
          {
            staticClass: "mdc-typography--headline2",
            staticStyle: { "margin-bottom": "1.2em", "text-align": "center" },
          },
          [_vm._v("\n          Proč se učit s Lingly?\n        ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-layout-grid__inner" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet",
            },
            [
              _c("div", { staticClass: "pm-card pm-card--course mdc-card" }, [
                _c("div", { staticClass: "pm-card__content" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "mdc-typography--headline5",
                      staticStyle: {
                        "margin-bottom": "0.6em",
                        "font-weight": "700",
                      },
                    },
                    [
                      _vm._v(
                        "\n                  Tvoříme kvalitní obsah\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mdc-typography--headline6" }, [
                    _vm._v(
                      "\n                  Každou naši lekci namlouvá rodilý mluvčí a pečlivě kontrolují zkušení lektoři. Samozřejmostí jsou české překlady (bez chyb!) a doprovodné fotografie.\n                "
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet",
            },
            [
              _c("div", { staticClass: "pm-card pm-card--course mdc-card" }, [
                _c("div", { staticClass: "pm-card__content" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "mdc-typography--headline5",
                      staticStyle: {
                        "margin-bottom": "0.6em",
                        "font-weight": "700",
                      },
                    },
                    [
                      _vm._v(
                        "\n                  Učíme praktickou angličtinu\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mdc-typography--headline6" }, [
                    _vm._v(
                      "\n                  Vše, co se u nás naučíte, můžete ihned začít používat – na dovolené, v práci, ve škole nebo na rande. 😉\n                "
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet",
            },
            [
              _c("div", { staticClass: "pm-card pm-card--course mdc-card" }, [
                _c("div", { staticClass: "pm-card__content" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "mdc-typography--headline5",
                      staticStyle: {
                        "margin-bottom": "0.6em",
                        "font-weight": "700",
                      },
                    },
                    [
                      _vm._v(
                        "\n                  Jsme vždy po ruce\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mdc-typography--headline6" }, [
                    _vm._v(
                      "\n                  Lingly se nemusí instalovat a bude vám fungovat na počítači, mobilu i tabletu. Díky tomu se můžete učit doma i na cestách.\n                "
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet",
            },
            [
              _c("div", { staticClass: "pm-card pm-card--course mdc-card" }, [
                _c("div", { staticClass: "pm-card__content" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "mdc-typography--headline5",
                      staticStyle: {
                        "margin-bottom": "0.6em",
                        "font-weight": "700",
                      },
                    },
                    [
                      _vm._v(
                        "\n                  Poradíme vám ❤️\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mdc-typography--headline6" }, [
                    _vm._v(
                      "\n                  Ať už plavete v gramatice nebo si nejste jisti slovíčkem, rádi vám poradíme. S námi na to nikdy nebudete sami.\n                "
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mdc-layout-grid__cell mdc-layout-grid__cell--span-12" },
      [
        _c(
          "div",
          {
            staticClass: "mdc-layout-grid__inner",
            staticStyle: { "grid-gap": "4em" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "mdc-typography--headline1",
                    staticStyle: { "line-height": "1.5" },
                  },
                  [
                    _vm._v(
                      "\n              Procvičování slovíček, poslechu i gramatiky.\n            "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "mdc-typography--headline5",
                    staticStyle: {
                      "margin-top": "0.5em",
                      "line-height": "1.8",
                    },
                  },
                  [
                    _vm._v("\n              V Lingly najdete "),
                    _c("strong", [_vm._v("tisíce otázek")]),
                    _vm._v(
                      " zaměřených na rozšiřování slovní zásoby, trénování poslechu nebo třeba pilování gramatiky. Je na vás, co si zrovna vyberete.\n            "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone",
              },
              [
                _c("div", { staticClass: "pm-card pm-card--course mdc-card" }, [
                  _c("div", { staticClass: "pm-card__content" }, [
                    _c("img", {
                      staticStyle: { "max-width": "100%" },
                      attrs: {
                        src: require("../../assets/landing_sample_1.png"),
                        alt: "Ukázka aplikace",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone",
              },
              [
                _c("div", { staticClass: "pm-card pm-card--course mdc-card" }, [
                  _c("div", { staticClass: "pm-card__content" }, [
                    _c("img", {
                      staticStyle: { "max-width": "100%" },
                      attrs: {
                        src: require("../../assets/landing_sample_2.png"),
                        alt: "Ukázka aplikace",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mdc-notched-outline" }, [
      _c("span", { staticClass: "mdc-notched-outline__leading" }),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-notched-outline__notch" }, [
        _c("span", { staticClass: "mdc-floating-label" }, [_vm._v("Jméno")]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-notched-outline__trailing" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mdc-notched-outline" }, [
      _c("span", { staticClass: "mdc-notched-outline__leading" }),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-notched-outline__notch" }, [
        _c("span", { staticClass: "mdc-floating-label" }, [_vm._v("E-mail")]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-notched-outline__trailing" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mdc-notched-outline" }, [
      _c("span", { staticClass: "mdc-notched-outline__leading" }),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-notched-outline__notch" }, [
        _c("label", { staticClass: "mdc-floating-label" }, [
          _vm._v("Na co byste se chtěli zeptat?"),
        ]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-notched-outline__trailing" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("small", [_vm._v("© 2020 Lingly s.r.o.")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }